import { AppState } from 'redux/store';
import DropdownMenu from 'components/dropdown-menu/dropdown-menu';
import { ICellRendererParams } from 'ag-grid-community';
import { OpenIcon } from 'icons/open';
import { AccessLevel, Permission } from 'services/permission.model';
import { PermissionGuardSelectors } from 'modules/permission-guard/permission-guard.controller';
import { OpenRootIcon } from 'icons/open-root';
import { OpenSecondaryIcon } from 'icons/open-secondary';
import { ArrowSquareRight } from 'icons/arrow-square-right';
import { useDispatch, useSelector } from 'react-redux';
import { TasksActions } from 'pages/tasks/tasks.controller';
import { TaskTableModel } from 'services/task-table.model';
import { ChainIcon } from 'icons/chain';
import { ProductionStatusEnum } from 'types/status-enums';

type Props = ICellRendererParams<TaskTableModel>;

const MoreActionsMenuCell = ({ ...props }: Props) => {
  const dispatch = useDispatch();

  const taskDepartments = props.data?.department_ids.map((i) => ({ id: i })) || [];
  const accessLevels = [AccessLevel.userDepartment, AccessLevel.userDepartmentSubDepartments, AccessLevel.allDepartments];
  const isPermitted = useSelector((state: AppState) =>
    PermissionGuardSelectors.checkEditLevel(state, Permission.webTaskEditLevel, accessLevels, taskDepartments),
  );

  const excludedStatusesForPriorityMenu: ProductionStatusEnum[] = [
    ProductionStatusEnum.Done,
    ProductionStatusEnum.To_Do,
    ProductionStatusEnum.Canceled,
    ProductionStatusEnum.From_Stock,
    ProductionStatusEnum.Launching,
  ];

  const { data } = props;

  if (!data) return null;

  const copyLink = {
    onClick: () => dispatch(TasksActions.copyTaskLink(data?.id)),
    key: 'copy_url_for_task',
    text: 'Copy link',
    bottomDivider: false,
    icon: <ChainIcon width={18} height={18} />,
  };

  const openTask = {
    onClick: () => dispatch(TasksActions.openTaskInNewTab(data?.id)),
    key: 'action_open_task',
    text: 'Open task',
    bottomDivider: false,
    icon: <OpenIcon width={18} height={18} />,
  };

  const openRootProduction = {
    onClick: () => dispatch(TasksActions.openRootOrMainProductionInNewTab(data?.root_production_workflow_id)),
    key: 'action_open_root_production',
    text: 'Open root production',
    bottomDivider: false,
    icon: <OpenRootIcon width={18} height={18} />,
  };

  const openProduction = {
    onClick: () => dispatch(TasksActions.openRootOrMainProductionInNewTab(data?.production_workflow_id)),
    key: 'action_open_production',
    text: 'Open production',
    bottomDivider: false,
    icon: <OpenSecondaryIcon width={18} height={18} />,
  };

  const manageTaskPriority = {
    onClick: () => dispatch(TasksActions.openManageTaskPriorityModal(data?.production_workflow_id, data?.id)),
    key: 'manage_tasks_priority_label',
    text: 'Manage tasks priority',
    icon: <ArrowSquareRight width={18} height={18} />,
  };

  const taskItemsMap = [
    copyLink,
    openTask,
    ...(data?.root_production_workflow_id ? [openRootProduction] : []),
    openProduction,
    ...(excludedStatusesForPriorityMenu.includes(data?.production_status) ? [] : [manageTaskPriority]),
  ];

  return <DropdownMenu id="collapse-content-card" items={taskItemsMap} isPermitted={isPermitted} />;
};

export default MoreActionsMenuCell;
