import { checkIfPlaneView } from 'pages/production/controllers/helpers';
import {
  RemoveProductionsArgs,
  UpdateMultipleProductionsRecursivelyArgs,
  UpdateProductionRecursivelyArgs,
} from 'pages/production/controllers/production-list-controller/types';
import {
  GroupByEnum,
  ProductionWorkflow,
  ProductionWorkflowOrderT,
  ProductionWorkflowProductT,
  ProductionWorkflowResponseDataT,
} from 'services/production-workflow.model';

const updateProduction = (
  id: string,
  value: Partial<ProductionWorkflow>,
  workflow: ProductionWorkflow,
  isReplaceComponent: boolean,
) => {
  if ((!value.order && workflow.id === id) || (isReplaceComponent && workflow.id === id)) {
    return {
      ...workflow,
      ...value,
    };
  }

  if (workflow.order.id === id) {
    return {
      ...workflow,
      order: { ...workflow.order, priority: value.order?.priority },
      nested_workflows: workflow.nested_workflows.length
        ? updateProductionRecursively({ productions: workflow.nested_workflows, isNested: true, id, value })
        : [],
    };
  }

  if (workflow.nested_workflows.length) {
    return {
      ...workflow,
      nested_workflows: updateProductionRecursively({ productions: workflow.nested_workflows, isNested: true, id, value }),
    };
  }

  return workflow;
};

export const updateProductionRecursively = ({ value, id, productions, isReplaceComponent }: UpdateProductionRecursivelyArgs) => {
  if (!productions.length) return productions;

  let newProductions: ProductionWorkflowResponseDataT;

  if ('production_workflows' in productions[0]) {
    newProductions = (productions as ProductionWorkflowProductT[]).map((workflow) => ({
      ...workflow,
      production_workflows: workflow.production_workflows.map((item) => {
        return updateProduction(id, value, item, !!isReplaceComponent);
      }),
    }));

    return newProductions;
  }

  newProductions = (productions as ProductionWorkflow[]).map((workflow) => {
    return updateProduction(id, value, workflow, !!isReplaceComponent);
  });

  return newProductions;
};

export const removeProductions = ({ groupIds, productionItems, productionIdsToRemove }: RemoveProductionsArgs) => {
  const filteredProductionItems =
    productionItems.groupBy === GroupByEnum.None
      ? productionItems.data?.filter((item) => !productionIdsToRemove.includes(item.id))
      : productionItems.data
          .map((item) => {
            if (groupIds.includes(item.id)) {
              return {
                ...item,
                total_count:
                  item.total_count -
                  productionIdsToRemove.filter((id) => item.production_workflows.some((i) => i.id === id)).length,
                production_workflows: item.production_workflows.filter((i) => !productionIdsToRemove.includes(i.id)),
                production_workflow_ids: item.production_workflow_ids.filter((id) => !productionIdsToRemove.includes(id)),
              };
            }

            return item;
          })
          .filter((item) => !!item.total_count);

  return filteredProductionItems;
};

export const updateMultipleProductionsRecursively = ({
  value,
  idsToUpdate,
  productionsTree,
}: UpdateMultipleProductionsRecursivelyArgs) => {
  let productionItems: ProductionWorkflowResponseDataT = [];

  if (checkIfPlaneView(productionsTree)) {
    productionItems = productionsTree.map((production) => {
      const updatedNestedWorkflows = production.nested_workflows.length
        ? updateMultipleProductionsRecursively({ value, idsToUpdate, productionsTree: production.nested_workflows })
        : production.nested_workflows;

      if (idsToUpdate.includes(production.id)) {
        return { ...production, ...value, nested_workflows: updatedNestedWorkflows };
      }

      return { ...production, nested_workflows: updatedNestedWorkflows };
    });
  } else {
    productionItems = productionsTree.map((group: ProductionWorkflowProductT | ProductionWorkflowOrderT) => ({
      ...group,
      production_workflows: updateMultipleProductionsRecursively({
        value,
        idsToUpdate,
        productionsTree: group.production_workflows,
      }),
    }));
  }

  return productionItems;
};

export const getAllProductionsOnTheScreen = (productions: ProductionWorkflow[]): ProductionWorkflow[] => {
  const selectedProductions: ProductionWorkflow[] = [];

  productions.forEach((workflow) => {
    selectedProductions.push(workflow);

    if (workflow.nested_workflows.length) {
      selectedProductions.push(...getAllProductionsOnTheScreen(workflow.nested_workflows));
    }
  });

  return selectedProductions;
};
