import { baseAxiosInstance } from 'axios-config';
import { TAKE_ITEMS } from 'constants/take-items';
import {
  WorkflowTemplate,
  CreateWorkflowTemplateData,
  UpdateWorkflowTemplateData,
  ManageWorkflowTemplatesData,
  WorkflowTemplateInfo,
  WorkflowTasksIndicatorsModel,
} from 'services/workflow-templates.model';
import { IdName } from 'types/common-types';

export class WorkflowTemplatesService {
  static async getAllWorkflowTemplates() {
    const { data } = await baseAxiosInstance.get<WorkflowTemplate[]>('/workflow-templates/all');

    return data;
  }

  static async getWorkflowById(id: string) {
    const { data } = await baseAxiosInstance.get<WorkflowTemplateInfo>(`/workflow-templates/info/${id}`);

    return data;
  }

  static async getWorkflowTasksInfo(id: string) {
    const { data } = await baseAxiosInstance.get<WorkflowTasksIndicatorsModel>(`/workflow-templates/tasks-info/${id}`);

    return data;
  }

  static async create(body: CreateWorkflowTemplateData) {
    const { data } = await baseAxiosInstance.post<WorkflowTemplate>('/workflow-templates/create', body);

    return data;
  }

  static async update(id: string, body: UpdateWorkflowTemplateData) {
    const { data } = await baseAxiosInstance.put<WorkflowTemplate>(`/workflow-templates/update/${id}`, body);

    return data;
  }

  static async delete(id: string) {
    const { data } = await baseAxiosInstance.delete<WorkflowTemplate>(`/workflow-templates/delete/${id}`);

    return data;
  }

  static async duplicate(body: IdName) {
    const { data } = await baseAxiosInstance.post<WorkflowTemplate>('/workflow-templates/duplicate', body);

    return data;
  }

  static async manageOrders(body: ManageWorkflowTemplatesData) {
    const { data } = await baseAxiosInstance.post<any>('/workflow-templates/manage-orders', body);

    return data;
  }

  static async getWorkflowTemplateNames(search = '', skip = 0, take = TAKE_ITEMS) {
    const { data } = await baseAxiosInstance.get<any>('/workflow-templates/find-names', {
      params: {
        search,
        skip,
        take,
      },
    });

    return data;
  }
}
