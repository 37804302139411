import { ViewPermissionGuard } from 'modules/permission-guard/view-permission-guard';
import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { SomethingWentWrong } from 'components/errors/something-went-wrong';
import { AuthorizeLayoutWrapper } from 'modules/authorized-layout/authorize-layout';
import Components from 'pages/components/components';
import { getNonAuthorizedEnvHostname } from 'tenant-helpers';
import { IconsPreview } from 'icons/icons-preview/icons-preview';
import { Paths } from 'routes/paths';
import PagePlug from 'pages/page-plug/page-plug';
import AddUserFlow from 'modules/add-user-flow/add-user-flow';
import ManageFailedTasks from 'modules/manage-failed-tasks-modal/manage-failed-tasks-modal';
import Departments from 'pages/departments/departments';
import Settings from 'pages/settings/settings';
import { AccessLevel, Permission } from 'services/permission.model';
import { PrintLabelWithQrModal, LabelWithQrConnected } from 'modules/print-label-with-qr/label-with-qr-modal';
import { ProductionTasksList } from 'pages/production-tasks-list/production-tasks-list';
import { useCompanyRoutesRedirect } from './hooks/use-company-routes-redirect';
import Spinner from '../components/spinner/spinner';
import lazyWithRetry from '../components/lazy-with-retry/lazy-with-retry';
import CreateNewPassword from '../pages/auth/components/create-new-password/create-new-password';
import InvalidLink from '../pages/auth/components/invalid-link/invalid-link';
import ForgotPassword from '../pages/auth/components/forgot-password/forgot-password';

const HomePage = lazyWithRetry(() => import('pages/home/home' /* webpackChunkName: "HomePage" */));
const ProductionPage = lazyWithRetry(() => import('pages/production/production' /* webpackChunkName: "ProductionPage" */));
const TasksPage = lazyWithRetry(() => import('pages/tasks/tasks' /* webpackChunkName: "TasksPage" */));
const ProductionWorkflowPage = lazyWithRetry(
  () => import('pages/production-workflow/production-workflow' /* webpackChunkName: "ProductionWorkflow" */),
);
const ProductSearch = lazyWithRetry(() => import('pages/product-search/product-search' /* webpackChunkName: "ProductSearch" */));
const AnalyticsPage = lazyWithRetry(() => import('pages/analytics/analytics' /* webpackChunkName: "AnalyticsPage" */));
const ProductsPage = lazyWithRetry(() => import('pages/products/all-products' /* webpackChunkName: "ProductsPage" */));
const TaskPage = lazyWithRetry(() => import('pages/task/task'));
const ProductPage = lazyWithRetry(() => import('pages/product-flow/pages/product/product' /* webpackChunkName: "ProductPage" */));
const WorkflowTemplate = lazyWithRetry(
  () => import('pages/product-flow/pages/workflow-template/workflow-template' /* webpackChunkName: "WorkflowTemplate" */),
);
const WorkflowTaskTemplate = lazyWithRetry(
  () =>
    import(
      'pages/product-flow/pages/workflow-task-template/workflow-task-template' /* webpackChunkName: "WorkflowTaskTemplate" */
    ),
);
const UsersPage = lazyWithRetry(() => import('pages/users/users'));
const UserPage = lazyWithRetry(() => import('pages/user/user'));
const AuthPage = lazyWithRetry(() => import('pages/auth/auth' /* webpackChunkName: "SignInPage" */));

export const CompanyRoutes = () => {
  useCompanyRoutesRedirect();

  return (
    <Suspense fallback={<Spinner />}>
      <AuthorizeLayoutWrapper>
        <Routes>
          {/* Common paths START */}
          <Route errorElement={<SomethingWentWrong />} element={<AuthPage />}>
            <Route path={Paths.CreatePassword} errorElement={<SomethingWentWrong />} element={<CreateNewPassword />} />
            <Route path={Paths.ForgotPassword} errorElement={<SomethingWentWrong />} element={<ForgotPassword />} />
          </Route>
          <Route path={Paths.InvalidLink} errorElement={<SomethingWentWrong />} element={<InvalidLink />} />
          <Route
            path={Paths.CompanyAccessDenied}
            errorElement={<SomethingWentWrong />}
            element={
              <PagePlug
                withUrl
                title="Access is denied"
                subtitle="You don`t have permission to access this page. Please contact your HESH administrator."
                onBackClick={async () => {
                  const destination = `${window.location.protocol}//${getNonAuthorizedEnvHostname()}/${Paths.SelectCompany}`;
                  window.location.replace(destination);
                }}
                onLogoClick={() => {
                  const destination = `${window.location.protocol}//${getNonAuthorizedEnvHostname()}/sign-in`;
                  window.location.replace(destination);
                }}
              />
            }
          />
          <Route
            path={Paths.NotFound}
            errorElement={<SomethingWentWrong />}
            element={<PagePlug withUrl title="Not Found" subtitle="Entity is not exists. It could be deleted or moved" />}
          />
          <Route path={Paths.Home} errorElement={<SomethingWentWrong />} element={<HomePage />} />
          {/* Common paths END */}

          {/* ANALYTICS */}
          <Route
            path={Paths.Analytics}
            errorElement={<SomethingWentWrong />}
            element={
              <ViewPermissionGuard permission={Permission.webAnalyticsView} accessLevel={[AccessLevel.access]}>
                <AnalyticsPage />
              </ViewPermissionGuard>
            }
          />

          {/* PRODUCTIONS */}
          <Route
            path={Paths.Production}
            errorElement={<SomethingWentWrong />}
            element={
              <ViewPermissionGuard permission={Permission.webProductionView} accessLevel={[AccessLevel.access]}>
                <ProductionPage />
              </ViewPermissionGuard>
            }
          />
          <Route
            path={`${Paths.ProductionWorkflow}/:id`}
            errorElement={<SomethingWentWrong />}
            element={
              <ViewPermissionGuard permission={Permission.webProductionView} accessLevel={[AccessLevel.access]}>
                <ProductionWorkflowPage />
              </ViewPermissionGuard>
            }
          />
          <Route
            path={`${Paths.Products}/*`}
            errorElement={<SomethingWentWrong />}
            element={
              <ViewPermissionGuard permission={Permission.webProductsView} accessLevel={[AccessLevel.access]}>
                <ProductsPage />
              </ViewPermissionGuard>
            }
          />
          <Route
            path={`${Paths.Users}/*`}
            errorElement={<SomethingWentWrong />}
            element={
              <ViewPermissionGuard permission={Permission.webUsersViewEdit} accessLevel={[AccessLevel.access]}>
                <UsersPage />
              </ViewPermissionGuard>
            }
          />
          <Route
            path={`${Paths.User}/:id`}
            errorElement={<SomethingWentWrong />}
            element={
              <ViewPermissionGuard permission={Permission.webUsersViewEdit} accessLevel={[AccessLevel.access]}>
                <UserPage />
              </ViewPermissionGuard>
            }
          />
          <Route
            path={`${Paths.Departments}/*`}
            errorElement={<SomethingWentWrong />}
            element={
              <ViewPermissionGuard permission={Permission.webDepartmentsViewEdit} accessLevel={[AccessLevel.access]}>
                <Departments />
              </ViewPermissionGuard>
            }
          />
          <Route
            path={`${Paths.Task}/:taskId`}
            errorElement={<SomethingWentWrong />}
            element={
              <ViewPermissionGuard permission={Permission.webTaskView} accessLevel={[AccessLevel.access]}>
                <TaskPage />
              </ViewPermissionGuard>
            }
          />
          <Route
            path={Paths.Tasks}
            errorElement={<SomethingWentWrong />}
            element={
              <ViewPermissionGuard permission={Permission.webTaskView} accessLevel={[AccessLevel.access]}>
                <TasksPage />
              </ViewPermissionGuard>
            }
          />
          {/* PRODUCT FLOW */}
          <Route
            path={`${Paths.Product}/:productId`}
            errorElement={<SomethingWentWrong />}
            element={
              <ViewPermissionGuard permission={Permission.webProductsView} accessLevel={[AccessLevel.access]}>
                <ProductPage />
              </ViewPermissionGuard>
            }
          />
          <Route
            path={Paths.ProductSearch}
            errorElement={<SomethingWentWrong />}
            element={
              <ViewPermissionGuard permission={Permission.webProductsView} accessLevel={[AccessLevel.access]}>
                <ProductSearch />
              </ViewPermissionGuard>
            }
          />
          {/* WORKFLOW TEMPLATE */}
          <Route
            path={`${Paths.WorkFlowTemplate}/:id`}
            errorElement={<SomethingWentWrong />}
            element={
              <ViewPermissionGuard permission={Permission.webProductsView} accessLevel={[AccessLevel.access]}>
                <WorkflowTemplate />
              </ViewPermissionGuard>
            }
          />
          <Route
            path={`${Paths.TaskTemplate}/:id`}
            errorElement={<SomethingWentWrong />}
            element={
              <ViewPermissionGuard permission={Permission.webProductsView} accessLevel={[AccessLevel.access]}>
                <WorkflowTaskTemplate />
              </ViewPermissionGuard>
            }
          />
          <Route
            path={Paths.Settings}
            errorElement={<SomethingWentWrong />}
            element={
              <ViewPermissionGuard permission={Permission.webSettingsViewEdit} accessLevel={[AccessLevel.access]}>
                <Settings />
              </ViewPermissionGuard>
            }
          />

          {/* FOR DEV PURPOSES */}
          {process.env.NODE_ENV === 'development' && (
            <>
              <Route path={Paths.Components} errorElement={<SomethingWentWrong />} element={<Components />} />
              <Route path={Paths.Icons} element={<IconsPreview />} />
            </>
          )}

          <Route
            path={`${Paths.ProductionTasksList}/:id`}
            element={
              <ViewPermissionGuard permission={Permission.webProductionView} accessLevel={[AccessLevel.access]}>
                <ProductionTasksList />
              </ViewPermissionGuard>
            }
          />

          <Route path="*" element={<Navigate to={Paths.NotFound} />} />
        </Routes>

        <AddUserFlow />
        <ManageFailedTasks />
        <PrintLabelWithQrModal />
        <LabelWithQrConnected isHidden />
      </AuthorizeLayoutWrapper>
    </Suspense>
  );
};
