import { AppState } from 'redux/store';
import { LockIcon } from 'icons/lock-icon';
import { Stack, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { TasksActions } from 'pages/tasks/tasks.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import { PermissionGuardActions, PermissionGuardSelectors } from 'modules/permission-guard/permission-guard.controller';
import './is-in-queue-cell.scss';

const IsInQueueCell = ({ value, data }) => {
  const dispatch = useDispatch();

  const isPermitted = useSelector((state: AppState) =>
    PermissionGuardSelectors.selectIsPermitted(state, Permission.webManageInQueueState, [AccessLevel.access]),
  );

  const toggleTaskQueueStatus = () => {
    if (!isPermitted) {
      dispatch(PermissionGuardActions.openModal());

      return;
    }

    dispatch(TasksActions.updateTask(data.id, { is_in_queue: !value }));
  };

  return (
    <Stack className="lock_container" data-is-visible={value}>
      <Tooltip title={`Press to ${value ? 'unlock' : 'lock'} the task`} placement="top">
        <button type="button" data-is-visible={value} className="lock_wrapper" onClick={toggleTaskQueueStatus}>
          <LockIcon />
        </button>
      </Tooltip>
    </Stack>
  );
};

export default IsInQueueCell;
