import DropdownSelector from 'components/ui-new/dropdown-permission-selector/dropdown-permission-selector';
import { LanguageName, SettingsActions } from 'pages/settings/settings.controller';
import s from 'pages/settings/settings.module.scss';
import { connect } from 'react-redux';
import { AppState } from 'redux/store';
import { AppLanguage } from 'types/common-enums';
import { IdName } from 'types/common-types';

type StateProps = {
  language: IdName;
};

type DispatchProps = {
  onChangeLanguage: (value: IdName) => void;
};

type Props = StateProps & DispatchProps;

const options = {
  appLanguage: [
    {
      id: AppLanguage.English,
      name: LanguageName.English,
    },
    {
      id: AppLanguage.Ukrainian,
      name: LanguageName.Ukrainian,
    },
  ],
};

const MobileTabContent = ({ language, onChangeLanguage }: Props) => {
  return (
    <div className={s.section}>
      <div className={s.row}>
        <b className={s.row_title}>Mobile app default language</b>
        <div className={s.right_col}>
          <DropdownSelector value={language} isFullDropdownWidth options={options.appLanguage} onChange={onChangeLanguage} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  language: state.settings.language,
});

const mapDispatchToProps: DispatchProps = {
  onChangeLanguage: SettingsActions.onChangeLanguage,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileTabContent);
