import {
  TaskResponsibilityCreateRequest,
  TaskResponsibilityUpdateRequest,
  SuggestedUsersResponse,
} from 'services/task-responsibility.model';
import { PAGE_SIZE } from 'components/ui-new/dropdown-user-search-selector/dropdown-user-search-selector';
import { UserShortModel } from 'services/user.model';
import { PaginatedResponse } from 'types/common-types';
import { TaskResponsibilityModel } from './production-task.model';
import { baseAxiosInstance } from '../axios-config';

export class TaskResponsibilityService {
  public static async getSuggestedUsers(responsibilityId: string, skip: number = 0, take: number = PAGE_SIZE, search?: string) {
    const { data } = await baseAxiosInstance.get<SuggestedUsersResponse<UserShortModel[]>>(
      `/task-responsibility/suggested-users/${responsibilityId}`,
      {
        params: {
          search,
          skip,
          take,
        },
      },
    );
    return data;
  }

  public static async getOtherUsers(responsibilityId: string, skip: number = 0, take: number = PAGE_SIZE, search?: string) {
    const { data } = await baseAxiosInstance.get<PaginatedResponse<UserShortModel[]>>(
      `/task-responsibility/other-users/${responsibilityId}`,
      {
        params: {
          search,
          skip,
          take,
        },
      },
    );
    return data;
  }

  public static async createResponsibility(dto: TaskResponsibilityCreateRequest) {
    const { data } = await baseAxiosInstance.post<TaskResponsibilityModel>('/task-responsibility/create', dto);
    return data;
  }

  public static async deleteResponsibility(id: string) {
    const { data } = await baseAxiosInstance.delete<TaskResponsibilityModel>(`/task-responsibility/${id}`);
    return data;
  }

  public static async updateResponsibility(id: string, dto: TaskResponsibilityUpdateRequest) {
    const { data } = await baseAxiosInstance.put<TaskResponsibilityModel>(`/task-responsibility/${id}`, dto);
    return data;
  }
}
