import { baseAxiosInstance } from 'axios-config';
import { TAKE_ITEMS } from 'constants/take-items';
import {
  AllDepartmentsByPage,
  CreateDepartamentData,
  DepartmensByPageResponse,
  Department,
  DepartmentById,
  DepartmentWithPath,
  CanDeleteResponseItem,
} from './department.model';

export class DepartmentService {
  public static async getAllDepartamentsByPage(body: { skip?: number; take?: number; search?: string; user_count?: boolean }) {
    const { data } = await baseAxiosInstance.get<DepartmensByPageResponse>(`/departments/all-by-page`, { params: body });

    return data;
  }

  public static async getAllDepartaments(search = '', skip = 0, take = TAKE_ITEMS) {
    const { data } = await baseAxiosInstance.get<AllDepartmentsByPage>(`/departments/all-by-page`, {
      params: {
        search,
        skip,
        take,
      },
    });
    return data;
  }

  public static async getDepartamentById(departmentId: string) {
    const { data } = await baseAxiosInstance.get<Department>(`/departments/${departmentId}`);
    return data;
  }

  public static async getDepartamentWithChildren(departmentId: string | null) {
    const { data } = await baseAxiosInstance.get<DepartmentById>(
      `/departments/with-children/${departmentId ? `${departmentId}` : ','}`,
    );
    return data;
  }

  public static async deleteDepartament(departmentId: string) {
    return baseAxiosInstance.delete(`/departments/delete/${departmentId}`);
  }

  public static async updateDepartament(departmentId: string, departmentData: CreateDepartamentData) {
    const { data } = await baseAxiosInstance.put<Department>(`/departments/update/${departmentId}`, departmentData);
    return data;
  }

  public static async createDepartament(departmentData: CreateDepartamentData) {
    const { data } = await baseAxiosInstance.post<Department>('/departments/create', departmentData);
    return data;
  }

  public static async getAllDepartmentsWithPath(keyword: string = '') {
    const { data } = await baseAxiosInstance.get<DepartmentWithPath[]>('/departments/with-path', {
      params: {
        search: keyword,
      },
    });
    return data;
  }

  public static async updateParentDepartment(parentId: string, ids: string[]) {
    const { data } = await baseAxiosInstance.put('/departments/update-parent-department', {
      parent_department_id: parentId,
      ids,
    });
    return data;
  }

  public static async canDeleteDepartments(ids: string[]) {
    const { data } = await baseAxiosInstance.post<CanDeleteResponseItem[]>('/departments/can-delete', ids);
    return data;
  }

  public static async deleteSeveralDepartments(ids: string[]) {
    const { data } = await baseAxiosInstance.post('/departments/delete-many', ids);
    return data;
  }
}
