import { Popover, Stack, Typography } from '@mui/material';
import Checkbox from 'components/checkbox/checkbox';
import { SearchIcon } from 'icons/search';
import { DropDownProps, TasksFiltersEnum } from 'pages/tasks/types/types';
import { useDispatch, useSelector } from 'react-redux';
import { TasksActions } from 'pages/tasks/tasks.controller';
import { AppState } from 'redux/store';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { notify } from 'notifications';
import Skeleton from 'components/ui-new/skeleton/skeleton';
import { UserShortModel } from 'services/user.model';
import { PaginateResponse } from 'types/paginate-response';
import { FailReasonsModel } from 'services/production-task.model';
import { IdName } from 'types/common-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce } from 'utils/debounce';
import { booleanFilters } from 'pages/tasks/constants';
import { getPopoverWidth } from 'pages/tasks/modules/drop-down-search-filter/helpers';
import s from './drop-down-search-filter.module.scss';
import floatingFilterStyle from '../floating-filter/floating-filter.module.scss';

type Props = {
  fieldName: TasksFiltersEnum;
  fetchOptionsFunction?: (params: {
    search?: string;
    skip?: number;
    take?: number;
  }) => Promise<PaginateResponse<UserShortModel | FailReasonsModel>>;
} & DropDownProps;

const ITEMS_PER_PAGE = 25;

const skeleton = Array(5)
  .fill(null)
  .map((item, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Stack flexDirection="row" width="100%" gap="6px" key={index}>
      <Skeleton width="20px" />
      <Skeleton width="100%" />
    </Stack>
  ));

const DropDownSearchFilter = ({
  isOpen,
  anchorEl,
  fieldName,
  includeUnassignedOption = false,
  fetchOptionsFunction,
  onClose,
}: Props) => {
  const dispatch = useDispatch();
  const filter = useSelector((state: AppState) => state.tasks.filters.filters[fieldName]);

  const [searchValue, setSearchValue] = useState(filter?.value || '');
  const [isLoading, setIsLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({ next: 0, total: 0 });
  const firstRender = useRef(true);

  const mapOptions = (data: any[], filterField: TasksFiltersEnum, isInitialLoad: boolean): IdName[] => {
    switch (filterField) {
      case TasksFiltersEnum.ReasonForFailure:
        return data.map((option: FailReasonsModel) => ({ id: option.en_reason, name: option.en_reason }));
      case TasksFiltersEnum.Assignee:
        // eslint-disable-next-line no-case-declarations
        const assigneeOptions = data.map((option: UserShortModel) => ({
          id: option.id,
          name: `${option.first_name} ${option.last_name}`,
          avatar_image_url: option.avatar_image_url,
        }));

        return includeUnassignedOption && isInitialLoad
          ? [{ id: 'unassigned', name: 'Unassigned' }, ...assigneeOptions]
          : assigneeOptions;
      default:
        return [];
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    debounceFetchOptions(event.target.value);
  };

  const debounceFetchOptions = (value: string) => {
    if (fetchOptionsFunction) {
      debounce(async () => {
        try {
          setIsLoading(true);

          const isSearchingUnassigned = 'unassigned'.includes(value.toLowerCase());
          const isFieldEmpty = value.trim() === '';

          const response = await fetchOptionsFunction({ take: ITEMS_PER_PAGE, skip: 0, search: value });

          setPaginationData({ next: response.meta.next || 0, total: response.meta.total });

          const mappedOptions = mapOptions(response.data, fieldName, false);

          let finalOptions = [...mappedOptions];

          if (includeUnassignedOption && (isSearchingUnassigned || isFieldEmpty)) {
            finalOptions = [{ id: 'unassigned', name: 'Unassigned' }, ...mappedOptions];
          }

          dispatch(TasksActions.setFilterOptions(fieldName, finalOptions));
        } catch (error) {
          notify.error(error.message);
        } finally {
          setIsLoading(false);
        }
      }, 500);
    }
  };

  const handleSelect = (id: string) => {
    dispatch(TasksActions.onFilterChange(fieldName, searchValue, id));
  };

  const handleSelectAll = () => {
    const allSelected = filter.options?.every((option) => filter.selectedOptions.includes(option.id));

    filter.options?.forEach((option) => {
      if (allSelected || !filter.selectedOptions.includes(option.id)) {
        dispatch(TasksActions.onFilterChange(fieldName, searchValue, option.id));
      }
    });
  };

  const handleReset = () => {
    filter.selectedOptions.forEach((optionId) => {
      dispatch(TasksActions.onFilterChange(fieldName, searchValue, optionId));
    });

    setSearchValue('');
  };

  const filteredOptions = filter?.options?.filter((option) => option.name.toLowerCase().includes(searchValue.toLowerCase()));
  const isAllSelected = filter?.options?.every((option) => filter.selectedOptions.includes(option.id));

  useEffect(() => {
    if (!fetchOptionsFunction || !isOpen || !firstRender.current) return;

    const fetchOptions = async () => {
      try {
        setIsLoading(true);

        const response = await fetchOptionsFunction({ take: ITEMS_PER_PAGE, skip: 0 });

        setPaginationData({ next: response.meta.next || 0, total: response.meta.total });

        const mappedOptions = mapOptions(response.data, fieldName, true);
        dispatch(TasksActions.setFilterOptions(fieldName, mappedOptions));

        firstRender.current = false;
      } catch (error) {
        notify.error(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOptions();
  }, [isOpen]);

  return (
    <Popover
      open={isOpen}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: booleanFilters.includes(fieldName) ? 'right' : 'left' }}
      slotProps={{
        paper: { className: floatingFilterStyle.popover, style: { width: getPopoverWidth(fieldName, booleanFilters) } },
      }}
    >
      <Stack gap="8px" alignItems="flex-end">
        {!booleanFilters.includes(fieldName) && (
          <Stack width="100%" position="relative">
            <input
              className={`${floatingFilterStyle.input} ${s.search_input}`}
              value={searchValue}
              onChange={handleInputChange}
            />
            <SearchIcon className={s.search_icon} width={18} height={18} stroke="#141414" />
          </Stack>
        )}
        {isLoading ? (
          skeleton
        ) : (
          <div className={s.scrollable} id="scroll">
            {!booleanFilters.includes(fieldName) && (
              <Stack flexDirection="row" alignItems="center" gap="6px">
                <Checkbox size="medium" checked={isAllSelected} onChange={handleSelectAll} />
                <Typography>Select all</Typography>
              </Stack>
            )}
            <InfiniteScroll
              scrollableTarget="scroll"
              className={s.infinite_scroll}
              dataLength={filter?.options.length}
              hasMore={filter?.options.length < paginationData.total}
              next={async () => {
                if (!fetchOptionsFunction) return;

                const response = await fetchOptionsFunction({ take: ITEMS_PER_PAGE, skip: paginationData.next });

                setPaginationData({ next: response.meta.next || 0, total: response.meta.total });

                const mappedOptions = mapOptions(response.data, fieldName, false);
                dispatch(TasksActions.setFilterOptions(fieldName, [...filter.options, ...mappedOptions]));
              }}
              loader={null}
            >
              {(fetchOptionsFunction ? filter.options : filteredOptions)?.map((item) => (
                <Stack flexDirection="row" alignItems="center" gap="6px" key={item.id} onClick={() => handleSelect(item.id)}>
                  <Checkbox size="medium" checked={filter.selectedOptions.includes(item.id)} />
                  <Typography whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                    {item.name}
                  </Typography>
                </Stack>
              ))}
            </InfiniteScroll>
          </div>
        )}
        {!booleanFilters.includes(fieldName) && (
          <button className={s.apply_btn} type="button" onClick={handleReset}>
            Reset
          </button>
        )}
      </Stack>
    </Popover>
  );
};

export default DropDownSearchFilter;
