import { baseAxiosInstance } from 'axios-config';
import { TAKE_ITEMS } from 'constants/take-items';
import { PaginatedResponse } from 'types/common-types';
import { Client, newClientRequestBody, NewClientResponse } from 'services/client.model';

export class ClientService {
  public static async getAllClients(search = '', skip = 0, take = TAKE_ITEMS) {
    const { data } = await baseAxiosInstance.get<PaginatedResponse<Client[]>>(`/clients/all`, {
      params: {
        search,
        skip,
        take,
      },
    });

    return data;
  }

  public static async createClient(body: newClientRequestBody) {
    const data = await baseAxiosInstance.post<NewClientResponse>('/clients/create', body);

    return data;
  }
}
