import { ProductOptionsService } from 'services/product-options.service';
import { StateController } from 'state-controller';
import { IdName, IdNameOrder } from 'types/common-types';
import { ModalActions } from 'modules/root-modals/root-modals.controller';
import { MODALS } from 'modules/root-modals/modals';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import { DeleteConfirmationOwnProps } from 'modules/root-modals/modals/confirmation-modal/confirmation-modal';
import { Actions as ProductVariantController } from 'pages/product-flow/pages/product/controllers/product-variant.controller';
import { Actions as ProductRootController } from 'pages/product-flow/pages/product/controllers/product-root.controller';
import FireMinusIcon from 'icons/fire-minus';
import { v4 as uuidv4 } from 'uuid';
import { ProductConfigurationParameters } from 'services/product-configurations.model';
import { AppState, GetStateFunction } from 'redux/store';
import { CreateProductOptionData, ManageProductOptionData } from 'services/product-options.model';
import { ItemInterface } from 'react-sortablejs';
import { notify } from 'notifications';

export const MAX_PARAMETERS_COUNT = 3;

export enum ProductParameterTemplateModalNameEnum {
  AddParameterTemplate = 'addParameterTemplateModal',
}

export enum ModalActionEnum {
  Add = 'add',
  Edit = 'edit',
}

export type FieldsValidation = {
  name: string | null;
  nameTooltipWarning: string | null;
  values: string | null;
  value: string | null;
  valueTooltipWarning: string | null;
};

export type ProductParameterState = {
  isInitLoading: boolean;
  isProcessing: boolean;
  parameters: Array<ProductConfigurationParameters>;
  options: IdName[];
  [ProductParameterTemplateModalNameEnum.AddParameterTemplate]: {
    isOpen: boolean;
    isEditMode: boolean;
    isCreateProcessing: boolean;
    didTryToSave?: boolean;
    selectedTemplate: CreateProductOptionData;
    fieldsValidation: FieldsValidation;
  };
};

const defaultState: ProductParameterState = {
  isInitLoading: true,
  isProcessing: false,
  parameters: [],
  options: [],

  addParameterTemplateModal: {
    isOpen: false,
    isCreateProcessing: false,
    isEditMode: false,
    didTryToSave: false,
    selectedTemplate: {
      id: '',
      name: '',
      order: 0,
      product_configuration_id: '',
      product_option_values: [],
    },
    fieldsValidation: {
      name: '',
      nameTooltipWarning: '',
      values: '',
      value: '',
      valueTooltipWarning: '',
    },
  },
};

const stateController = new StateController<ProductParameterState>('PRODUCT_PARAMETER', defaultState);

export class Actions {
  public static init(parameters: Array<ProductConfigurationParameters>) {
    return (dispatch) => {
      dispatch(stateController.setState((prev) => ({ ...prev, parameters, isInitLoading: false })));
    };
  }

  public static disposeState() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static setInitLoading() {
    return (dispatch) => {
      dispatch(stateController.setState({ isInitLoading: true }));
    };
  }

  public static manageParametersOrder(newOrder: IdNameOrder[]) {
    return async (dispatch, getState: GetStateFunction) => {
      const { parameters } = getState().product.product_parameter;
      const { active_configuration } = getState().product.product_configurations;

      const data: ManageProductOptionData = {
        values: newOrder,
      };

      try {
        await ProductOptionsService.manageOrder(data);

        const newConfigurationsList: ProductConfigurationParameters[] = newOrder.map(({ id, order, name }) => {
          const currentParameter = parameters.find((item) => item.id === id);

          return {
            id,
            name,
            order,
            product_option_values: currentParameter?.product_option_values || [],
            product_configuration_id: currentParameter?.product_configuration_id,
          };
        });

        dispatch(stateController.setState({ parameters: newConfigurationsList }));
        dispatch(ProductVariantController.refetchVariants(active_configuration.id));
        dispatch(ProductRootController.updateProductModifiedAt());
      } catch (error) {
        dispatch(stateController.setState({ parameters }));
        notify.error(error.message);
      }
    };
  }

  public static deleteOption(id: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { active_configuration } = getState().product.product_configurations;

      try {
        dispatch(
          stateController.setState({
            isProcessing: true,
          }),
        );

        await ProductOptionsService.delete(id);
        dispatch(ProductRootController.updateProductModifiedAt());

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            parameters: prev.parameters.filter((item) => item.id !== id),
          })),
        );

        dispatch(ProductVariantController.refetchVariants(active_configuration.id));
      } finally {
        dispatch(
          stateController.setState({
            isProcessing: false,
          }),
        );
      }
    };
  }

  public static openDeleteConfirmationModal(data: ProductConfigurationParameters) {
    return (dispatch, getState: GetStateFunction) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const { active_configuration } = getState().product.product_configurations;

      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: 'Delete option?',
            text: (
              <span>
                Deleting the <strong>{data.name}</strong> parameter in the <strong>{active_configuration.name}</strong>{' '}
                configuration will change all related objects (within the current draft): <br />
                - product options; <br />
                - production templates; <br />
                - connection of parameters of nested products; <br />
                - task templates; <br />
                - bonuses are tied to this parameter. <br />
                <br />
                Are you sure you want to delete the <strong>{data.name}</strong> parameter?
              </span>
            ),
            icon: <FireMinusIcon />,
            withCloseButton: false,
            actionText: 'Delete',
            action: () => dispatch(Actions.deleteOption(data.id)),
          },
        }),
      );
    };
  }

  public static closeModal(modalName: ProductParameterTemplateModalNameEnum) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          [modalName]: defaultState[modalName],
        })),
      );
    };
  }

  public static openAddParameterTemplateModal() {
    return (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          addParameterTemplateModal: {
            ...prev.addParameterTemplateModal,
            isOpen: true,
          },
        })),
      );
    };
  }

  public static openEditParameterTemplateModal(data: ProductConfigurationParameters) {
    return (dispatch, getState: GetStateFunction) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductsEdit, [AccessLevel.access]))) {
        return;
      }

      const { id } = getState().product.product_configurations.active_configuration;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          addParameterTemplateModal: {
            ...prev.addParameterTemplateModal,
            isOpen: true,
            isEditMode: true,
            selectedTemplate: {
              id: data.id,
              name: data.name,
              order: data.order,
              product_option_values: data.product_option_values,
              product_configuration_id: id,
            },
          },
        })),
      );
    };
  }

  public static setSelectedTemplate(templateId: string) {
    return (dispatch, getState: GetStateFunction) => {
      const { productOptionTemplate } = getState().template_management_modal;
      const { active_configuration } = getState().product.product_configurations;
      const selectedTemplate = productOptionTemplate.find((item) => item.id === templateId);

      const createProductOptionData: CreateProductOptionData = {
        name: selectedTemplate?.name || '',
        order: 0,
        product_configuration_id: active_configuration.id,
        product_option_values:
          selectedTemplate?.product_option_template_values.map((valueItem) => ({
            id: `new-${valueItem.id}`,
            value: valueItem.value,
            order: Number(valueItem.order),
          })) || [],
      };

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          addParameterTemplateModal: {
            ...prev.addParameterTemplateModal,
            selectedTemplate: {
              ...createProductOptionData,
              id: prev.addParameterTemplateModal.selectedTemplate.id,
              order: prev.addParameterTemplateModal.selectedTemplate.order || 0,
            },
          },
        })),
      );
    };
  }

  public static onCreateOrUpdateProductOptions(actionType: ModalActionEnum) {
    return async (dispatch, getState: GetStateFunction) => {
      dispatch(Actions.productParametersTemplateValidation());
      const { selectedTemplate, fieldsValidation } = getState().product.product_parameter.addParameterTemplateModal;
      const { parameters } = getState().product.product_parameter;
      const { active_configuration } = getState().product.product_configurations;

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            addParameterTemplateModal: {
              ...prev.addParameterTemplateModal,
              isCreateProcessing: true,
            },
          })),
        );
        dispatch(
          stateController.setState((prevState) => ({
            ...prevState,
            addParameterTemplateModal: {
              ...prevState.addParameterTemplateModal,
              didTryToSave: true,
            },
          })),
        );

        if (Object.values(fieldsValidation).every((key) => !key)) {
          const postData: CreateProductOptionData = {
            name: selectedTemplate.name,
            order: parameters.length,
            product_configuration_id: selectedTemplate.product_configuration_id,
            product_option_values: selectedTemplate.product_option_values.map((item) => ({
              id: item.id?.includes('new-') ? undefined : item.id,
              value: item.value,
              order: item.order,
            })),
          };

          if (actionType === ModalActionEnum.Add) {
            const data = await ProductOptionsService.create({
              ...postData,
              product_option_values: postData.product_option_values.map((item) => ({
                order: item.order,
                value: item.value,
              })),
            });
            dispatch(
              stateController.setState((prevState) => ({
                ...prevState,
                parameters: [
                  ...prevState.parameters,
                  {
                    id: data.id,
                    product_option_values: data.product_option_values.map(({ id, value, order }) => ({ id, value, order })),
                    name: data.name,
                    order: prevState.parameters.length + 1,
                  },
                ],
              })),
            );
            dispatch(ProductRootController.updateProductModifiedAt());
          } else {
            if (!selectedTemplate.id) return;

            const data = await ProductOptionsService.update(selectedTemplate.id, {
              ...postData,
              order: postData.order,
            });
            dispatch(
              stateController.setState((prevState) => ({
                ...prevState,
                parameters: prevState.parameters.map((item) => {
                  if (item.id === selectedTemplate.id) {
                    return {
                      ...item,
                      order: postData.order,
                      product_option_values: data.product_option_values,
                      name: postData.name,
                    };
                  }
                  return item;
                }),
              })),
            );
            dispatch(ProductRootController.updateProductModifiedAt());
          }

          dispatch(ProductVariantController.refetchVariants(active_configuration.id));
          dispatch(Actions.closeModal(ProductParameterTemplateModalNameEnum.AddParameterTemplate));
        }
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            addParameterTemplateModal: {
              ...prev.addParameterTemplateModal,
              isCreateProcessing: false,
            },
          })),
        );
      }
    };
  }

  public static updateSelectedTemplateName(name: string) {
    return (dispatch, getState: GetStateFunction) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          addParameterTemplateModal: {
            ...prev.addParameterTemplateModal,
            selectedTemplate: {
              ...prev.addParameterTemplateModal.selectedTemplate,
              name,
            },
          },
        })),
      );
      if (getState().product.product_parameter.addParameterTemplateModal.didTryToSave) {
        dispatch(Actions.productParametersTemplateValidation());
      }
    };
  }

  public static updateProductOptionValueById(optionId: string, newValue: string) {
    return (dispatch, getState) => {
      const { selectedTemplate } = getState().product.product_parameter.addParameterTemplateModal;
      const optionIndex = selectedTemplate.product_option_values.findIndex((item) => item.id === optionId);

      if (optionIndex !== -1) {
        const updatedValues = [...selectedTemplate.product_option_values];
        updatedValues[optionIndex] = {
          ...updatedValues[optionIndex],
          value: newValue,
        };

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            addParameterTemplateModal: {
              ...prev.addParameterTemplateModal,
              selectedTemplate: {
                ...prev.addParameterTemplateModal.selectedTemplate,
                product_option_values: updatedValues,
              },
            },
          })),
        );

        if (getState().product.product_parameter.addParameterTemplateModal.didTryToSave) {
          dispatch(Actions.productParametersTemplateValidation());
        }
      }
    };
  }

  static addProductOptionValue(newValueName: string) {
    return (dispatch, getState: GetStateFunction) => {
      const { selectedTemplate } = getState().product.product_parameter.addParameterTemplateModal;
      const { active_configuration } = getState().product.product_configurations;
      const temporaryId = `new-${uuidv4()}`;

      const newValueObject = {
        id: temporaryId,
        value: newValueName,
        order: selectedTemplate.product_option_values.length,
      };

      const updatedValues = [...selectedTemplate.product_option_values, newValueObject];

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          addParameterTemplateModal: {
            ...prev.addParameterTemplateModal,
            selectedTemplate: {
              ...prev.addParameterTemplateModal.selectedTemplate,
              product_configuration_id: active_configuration.id,
              product_option_values: updatedValues,
            },
          },
        })),
      );
    };
  }

  static removeProductOptionValueById(optionId: string) {
    return (dispatch, getState) => {
      const { selectedTemplate } = getState().product.product_parameter.addParameterTemplateModal;
      const updatedValues = selectedTemplate.product_option_values
        .filter((item) => item.id !== optionId)
        .map((item, index) => ({
          ...item,
          order: index,
        }));

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          addParameterTemplateModal: {
            ...prev.addParameterTemplateModal,
            selectedTemplate: {
              ...prev.addParameterTemplateModal.selectedTemplate,
              product_option_values: updatedValues,
            },
          },
        })),
      );
    };
  }

  static onSetProductOptionValues(updatedValues: ItemInterface[]) {
    return (dispatch, getState: GetStateFunction) => {
      const { selectedTemplate } = getState().product.product_parameter.addParameterTemplateModal;

      if (selectedTemplate.product_option_values.length !== 0) {
        const updatedTemplate = {
          ...selectedTemplate,
          product_option_values: updatedValues,
        };

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            addParameterTemplateModal: {
              ...prev.addParameterTemplateModal,
              selectedTemplate: updatedTemplate,
            },
          })),
        );
      }
    };
  }

  public static productParametersTemplateValidation() {
    return (dispatch, getState: GetStateFunction) => {
      const { selectedTemplate } = getState().product.product_parameter.addParameterTemplateModal;
      const fieldsValidated = {
        name: '',
        nameTooltipWarning: '',
        values: '',
        valueTooltipWarning: '',
      };

      if (!selectedTemplate.name || selectedTemplate.name.trim() === '') {
        fieldsValidated.name = 'No title';
      }
      if (selectedTemplate.name.length >= 256) {
        fieldsValidated.nameTooltipWarning = 'Maximum character limit 255';
      } else {
        fieldsValidated.nameTooltipWarning = '';
      }
      if (!selectedTemplate.product_option_values.length || selectedTemplate.product_option_values.length === 0) {
        fieldsValidated.values = 'No parameter values';
      } else {
        selectedTemplate.product_option_values.forEach((param, paramIndex) => {
          if (!param.value || param.value.trim() === '') {
            fieldsValidated[`value${paramIndex}`] = `No value for parameter ${paramIndex + 1}`;
          }
          if (param.value.length >= 256) {
            fieldsValidated[`valueTooltipWarning${paramIndex}`] = `Maximum character limit 255`;
          } else {
            fieldsValidated[`valueTooltipWarning${paramIndex}`] = null;
          }
        });
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          addParameterTemplateModal: {
            ...prev.addParameterTemplateModal,
            fieldsValidation: fieldsValidated,
          },
        })),
      );
    };
  }

  public static openSaveConfirmationModal() {
    return (dispatch) => {
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: 'Save changes?',
            text: (
              <span>
                Deleting the Size parameter will result in the removal of this parameter&apos;s value from the products where it
                is applied. Are you sure you want to proceed with these changes?
              </span>
            ),
            icon: <FireMinusIcon />,
            withCloseButton: false,
            actionText: 'Save',
            action: () => {},
          },
        }),
      );
    };
  }
}

export class Selectors {
  public static isHadWarnings(state: AppState) {
    const { fieldsValidation } = state.product.product_parameter.addParameterTemplateModal;
    return Object.values(fieldsValidation).every((warning) => warning === null);
  }
}

export const reducer = stateController.getReducer();
