/* eslint-disable no-lonely-if */
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import {
  getDefaultSimilarValue,
  getDefaultVariantValue,
  checkAllValuesIsSimilar,
  checkAllVariantsIsSimilar,
} from 'pages/production/controllers/helpers';
import { v4 as uuidv4 } from 'uuid';
import {
  checkIfProductReadyToLaunch,
  groupProductionsForMultiLaunch,
  getDefaultProductionParameters,
  checkIfNotAllElementsConfigured,
  getProductionParametersByVariant,
} from 'pages/production/controllers/products-launch-modal-controller/helpers';
import {
  ProductForProductionVersion,
  ProductForProductionConfiguration,
  ProductForProductionVariant,
} from 'services/products.model';
import { ProductionListActions } from 'pages/production/controllers/production-list-controller/production-list.controller';
import {
  OnChangeKey,
  GroupedProductions,
  ProductForLaunchPreview,
  HandleBackAndNextPressArgs,
  OpenProductsLaunchModalArgs,
} from 'pages/production/controllers/products-launch-modal-controller/types';
import { ProductionsLaunchingProgressActions } from 'pages/production/production-launching-progress-modal/production-launching-progress-modal.controller';
import { Actions as ProductionWorkflowActions } from 'pages/production-workflow/controllers/production-workflow.controller';
import { LocationTheProductionStatusIsChangingFrom } from 'types/common-enums';
import { ProductionFiltersActions } from 'pages/production/controllers/production-filters-controller/production-filters.controller';
import { StateController } from '../../../../state-controller';
import { ProductsService } from '../../../../services/products.service';
import {
  ProductForLaunch,
  ProductionWorkflow,
  ProductionWorkflowType,
  ProductionWorkflowLaunchData,
  ProductionWorkflowMultiLaunchData,
} from '../../../../services/production-workflow.model';
import { AppState, GetStateFunction } from '../../../../redux/store';
import { ProductionWorkflowService } from '../../../../services/production-workflow.service';
import { ProductionStatusEnum } from '../../../../types/status-enums';
import { ProductForProductionWorkflow } from '../../../../services/products.model';
import { notify } from '../../../../notifications';

export type ProductsLaunchModalState = {
  isOpen: boolean;
  isLoading: boolean;
  primaryVersion: number;
  primaryVariantName: string;
  nestedFetchingIds: string[];
  products: ProductForLaunch[];
  primaryProductVariantId: string;
  primaryConfigurationName: string;
  productionForLaunchCount: number;
  productionIdToLoadDataFor: string;
  draftIdsOfParentProduction: { [key: string]: string[] };
  openedFrom: LocationTheProductionStatusIsChangingFrom;
  launchProductModal: {
    isOpen: boolean;
    isLaunching: boolean;
    products: ProductForLaunchPreview[];
  };
  warnings: {
    containsItself: string;
    differentVariants: string;
    primaryProductVariant: string;
    differentConfigurations: string;
  };

  // Multi Launch
  currentProductionIndex: number;
  groupedProductions: GroupedProductions[];
};

const defaultState: ProductsLaunchModalState = {
  products: [],
  nestedFetchingIds: [],
  draftIdsOfParentProduction: {},
  isOpen: false,
  isLoading: false,
  primaryVersion: 0,
  productionForLaunchCount: 0,
  primaryVariantName: '',
  primaryProductVariantId: '',
  primaryConfigurationName: '',
  productionIdToLoadDataFor: '',
  openedFrom: LocationTheProductionStatusIsChangingFrom.ProductionList,
  launchProductModal: {
    products: [],
    isOpen: false,
    isLaunching: false,
  },
  warnings: {
    containsItself: '',
    differentVariants: '',
    primaryProductVariant: '',
    differentConfigurations: '',
  },

  // MULTI LAUNCH
  groupedProductions: [],
  currentProductionIndex: 0,
};

const stateController = new StateController<ProductsLaunchModalState>('PRODUCTS_LAUNCH_MODAL', defaultState);

export class ProductsLaunchModalActions {
  public static openModal({
    version,
    openedFrom,
    variantName,
    productVariantId,
    configurationName,
    productionIdToLaunch,
    productionIdToLoadDataFor,
  }: OpenProductsLaunchModalArgs) {
    return async (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductionEdit, [AccessLevel.access]))) {
        return;
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          openedFrom,
          isOpen: true,
          primaryVersion: version,
          productionIdToLoadDataFor,
          primaryVariantName: variantName,
          primaryProductVariantId: productVariantId,
          primaryConfigurationName: configurationName,
        })),
      );
      dispatch(ProductsLaunchModalActions.initProductsWithVersions(productionIdToLaunch, productVariantId));
    };
  }

  public static openModalForManage(id: string) {
    return async (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductionEdit, [AccessLevel.access]))) {
        return;
      }

      dispatch(stateController.setState({ isOpen: true }));
      try {
        dispatch(stateController.setState({ isLoading: true }));
        const products = await ProductionWorkflowService.getInfoForManage(id);
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            isLoading: false,
            products,
          })),
        );
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static initProductsWithVersions(productionWorkflowId: string, productVariantId: string, isMultiLaunch?: boolean) {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        dispatch(stateController.setState({ isLoading: true, products: [] }));

        const {
          openedFrom,
          groupedProductions,
          primaryVariantName,
          currentProductionIndex,
          primaryConfigurationName,
          draftIdsOfParentProduction,
        } = getState().production.productsLaunchModal;

        const [product] = await ProductsService.getProductForProduction({ product_variant_id: productVariantId });
        let parrentIds: string[];
        if (!draftIdsOfParentProduction[currentProductionIndex || 0]?.length) {
          parrentIds = await ProductionWorkflowService.getDraftIdsOfParentProduction(productionWorkflowId);
        }

        // product version name expected v.1
        product.versions = product.versions.sort((a, b) => Number(b.name.slice(2)) - Number(a.name.slice(2)));
        let chosenVersion: ProductForProductionVersion | null;
        let chosenVariant: ProductForProductionVariant | null;
        let chosenConfiguration: ProductForProductionConfiguration | null;

        if (openedFrom === LocationTheProductionStatusIsChangingFrom.NewProductionModal) {
          ({ chosenVersion, chosenVariant, chosenConfiguration } = getProductionParametersByVariant(product, productVariantId));
        } else {
          if (isMultiLaunch) {
            const currentProduction = groupedProductions[currentProductionIndex];
            const configurationName = currentProduction.configuration;
            const variantName = currentProduction.variant.name;
            ({ chosenVersion, chosenVariant, chosenConfiguration } = getDefaultProductionParameters(
              product,
              configurationName,
              variantName,
            ));
          } else {
            ({ chosenVersion, chosenVariant, chosenConfiguration } = getDefaultProductionParameters(
              product,
              primaryConfigurationName,
              primaryVariantName,
            ));
          }
        }
        const chosenWorkflowTemplate = chosenConfiguration?.workflows.filter((i) => i.is_active)[0];

        dispatch(
          stateController.setState((prev) => {
            const products: ProductForLaunch[] = [
              {
                ...prev.products[0],
                id: productionWorkflowId,
                version: chosenVersion,
                configuration: chosenConfiguration,
                variant: chosenVariant,
                workflowTemplate: chosenWorkflowTemplate || null,
                position: 0,
                workflowType: ProductionWorkflowType.workflowTemplate,
                status: ProductionStatusEnum.To_Do,
                ...product,
              },
            ];

            return {
              ...prev,
              products,
              draftIdsOfParentProduction: {
                ...prev.draftIdsOfParentProduction,
                [currentProductionIndex]: parrentIds,
              },
              groupedProductions: prev.groupedProductions.map((production) => {
                return production.variant.id === productVariantId
                  ? { ...production, configuredProductions: products }
                  : production;
              }),
            };
          }),
        );
        dispatch(
          ProductsLaunchModalActions.onChangeWorkflowType(
            productionWorkflowId,
            ProductionWorkflowType.workflowTemplate,
            chosenVariant?.id,
          ),
        );

        dispatch(ProductsLaunchModalActions.checkContainsItself());
        dispatch(ProductsLaunchModalActions.checkDifferentVariants());
        dispatch(ProductsLaunchModalActions.checkDifferentConfigurations());
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static initNestedProducts(workflowTemplateId: string, nestedForId: string, productVariantId: string = '') {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            nestedFetchingIds: [nestedForId],
          })),
        );

        const nestedProducts = await ProductsService.getProductForProduction({ workflow_template_id: workflowTemplateId });
        const { products, draftIdsOfParentProduction, currentProductionIndex } = getState().production.productsLaunchModal;
        const isActive = products.some(
          (item) =>
            item?.version?.is_active &&
            item?.variant?.is_active &&
            item?.configuration?.is_active &&
            item?.workflowTemplate?.is_active,
        );
        if (nestedProducts.length !== 0 && isActive) {
          const sourceProductPosition = products.find((item) => item.id === nestedForId);
          const mappedNestedProducts = nestedProducts.map((product) => {
            const chosenVersion = product.versions.find((version) =>
              version.configurations.find((configuration) =>
                configuration.workflows.some((workflow) => workflow.id === product.workflow_template_id),
              ),
            );

            const mainConfigurationName = getState().production.productsLaunchModal.products[0].configuration?.name;
            const mainVariantName = getState().production.productsLaunchModal.products[0].variant?.name;

            const chosenConfiguration =
              getDefaultSimilarValue(mainConfigurationName, chosenVersion?.configurations || []) ||
              chosenVersion?.configurations[0];

            const chosenVariant =
              getDefaultVariantValue(mainVariantName, chosenConfiguration?.variants) || chosenConfiguration?.variants[0];

            const chosenWorkflow =
              chosenConfiguration?.workflows.find((workflow) => workflow.id === product.workflow_template_id) || null;

            // validation to except circular dependency
            const checkContainsItself = (checkedNestedForId: string, sourceId: string, index: number = 0): boolean => {
              const source = products.find((item) => item.id === checkedNestedForId);
              if (!source) {
                return false;
              }
              if (source.source_id === sourceId || draftIdsOfParentProduction[index]?.some((i) => sourceId === i)) {
                return true;
              }
              if (source.nested_for) {
                return checkContainsItself(source.nested_for, sourceId);
              }
              return false;
            };
            const isContainsItself = checkContainsItself(nestedForId, product.source_id, currentProductionIndex);

            return {
              isContainsItself,
              id: `${uuidv4()}-new`,
              version: chosenVersion,
              variant: chosenVariant,
              nested_for: nestedForId,
              workflowTemplate: chosenWorkflow,
              configuration: chosenConfiguration,
              status: ProductionStatusEnum.To_Do,
              position: (sourceProductPosition?.position || 0) + 1,
              ...product,
            };
          });
          const indexOfSourceProduct = products.findIndex((item) => item.id === nestedForId) + 1;
          dispatch(
            stateController.setState((prev) => {
              const newProducts = [
                ...prev.products.slice(0, indexOfSourceProduct),
                ...mappedNestedProducts,
                ...prev.products.slice(indexOfSourceProduct),
              ];

              return {
                ...prev,
                products: newProducts,
                groupedProductions: prev.groupedProductions.map((production) => {
                  return production.variant.id === productVariantId
                    ? { ...production, configuredProductions: newProducts }
                    : production;
                }),
              };
            }),
          );
          dispatch(ProductsLaunchModalActions.checkContainsItself());
          dispatch(ProductsLaunchModalActions.checkDifferentVariants());
          dispatch(ProductsLaunchModalActions.checkDifferentConfigurations());
        }
      } catch (err) {
        notify.error(err.message);
        throw err;
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            nestedFetchingIds: prev.nestedFetchingIds.filter((i) => i !== nestedForId),
          })),
        );
      }
    };
  }

  public static onChange(id: string, key: OnChangeKey, value: any, productVariantId: string) {
    return async (dispatch, getState: GetStateFunction) => {
      const { products } = getState().production.productsLaunchModal;
      const newProducts = products.map((product) => {
        if (product.id !== id) {
          return product;
        }
        return { ...product, [key]: value };
      });
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          products: newProducts,
          groupedProductions: prev.groupedProductions.map((production) => {
            return production.variant.id === productVariantId
              ? { ...production, configuredProductions: newProducts }
              : production;
          }),
        })),
      );
      if (key === 'workflowTemplate') {
        dispatch(ProductsLaunchModalActions.onChangeWorkflowTemplate(id, value, productVariantId));
      }
      if (key === 'workflowType') {
        dispatch(ProductsLaunchModalActions.onChangeWorkflowType(id, value, productVariantId));
      }
      if (key === 'variant' && id === products[0]?.id) {
        const { primaryProductVariantId } = getState().production.productsLaunchModal;
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            warnings: {
              ...prev.warnings,
              primaryProductVariant:
                newProducts[0]?.variant?.id === primaryProductVariantId
                  ? ''
                  : 'Are you sure you want to change the primary product settings?',
            },
          })),
        );
      }
      if (key === 'version') {
        dispatch(ProductsLaunchModalActions.setDefaultConfiguration(id, productVariantId));
        dispatch(ProductsLaunchModalActions.setDefaultVariant(id, productVariantId));
      }
      if (key === 'configuration') {
        dispatch(ProductsLaunchModalActions.setDefaultVariant(id, productVariantId));
      }

      dispatch(ProductsLaunchModalActions.checkDifferentVariants());
      dispatch(ProductsLaunchModalActions.checkDifferentConfigurations());
      dispatch(ProductsLaunchModalActions.checkContainsItself());
    };
  }

  public static setDefaultVariant(id: string, productVariantId: string) {
    return async (dispatch, getState: () => AppState) => {
      const { products } = getState().production.productsLaunchModal;

      const indexOfSourceProduct = products.findIndex((item) => item.id === id);
      const mainVariantName = getState().production.productsLaunchModal.products[0]?.variant?.name;
      const sourceProduct = products[indexOfSourceProduct];

      const updatedProduct = {
        ...sourceProduct,
        variant:
          indexOfSourceProduct !== 0
            ? getDefaultVariantValue(mainVariantName, sourceProduct.configuration?.variants || [])
            : products[0].configuration?.variants[0],
      };

      const updatedProducts = [...products];
      updatedProducts[indexOfSourceProduct] = updatedProduct;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          products: updatedProducts,
          groupedProductions: prev.groupedProductions.map((production) => {
            return production.variant.id === productVariantId
              ? { ...production, configuredProductions: updatedProducts }
              : production;
          }),
        })),
      );
    };
  }

  public static setDefaultConfiguration(id: string, productVariantId: string) {
    return async (dispatch, getState: () => AppState) => {
      const { products } = getState().production.productsLaunchModal;

      const indexOfSourceProduct = products.findIndex((item) => item.id === id);
      const mainConfigurationName = getState().production.productsLaunchModal.products[0].configuration?.name;
      const sourceProduct = products[indexOfSourceProduct];

      const updatedProduct = {
        ...sourceProduct,
        configuration:
          indexOfSourceProduct !== 0
            ? getDefaultSimilarValue(mainConfigurationName, sourceProduct.version?.configurations)
            : products[0].version?.configurations[0],
      };

      const updatedProducts = [...products];
      updatedProducts[indexOfSourceProduct] = updatedProduct;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          products: updatedProducts,
          groupedProductions: prev.groupedProductions.map((production) => {
            return production.variant.id === productVariantId
              ? { ...production, configuredProductions: updatedProducts }
              : production;
          }),
        })),
      );
    };
  }

  public static checkContainsItself() {
    return (dispatch, getState: GetStateFunction) => {
      const { products } = getState().production.productsLaunchModal;
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          warnings: {
            ...prev.warnings,
            containsItself: products.some((item) => !!item.isContainsItself) ? 'Product contains itself as component' : '',
          },
        })),
      );
    };
  }

  public static checkDifferentVariants() {
    return async (dispatch, getState: () => AppState) => {
      try {
        const { products } = getState().production.productsLaunchModal;
        const mainProductVariant = products[0]?.variant?.name;
        const productsMapped = products.slice(1).map((i) => ({ name: i?.variant?.name || '' }));

        if (!mainProductVariant) {
          throw new Error('mainProductVariant is undefined');
        }

        const isSameVariants = productsMapped.length ? checkAllVariantsIsSimilar(mainProductVariant, productsMapped) : true;

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            warnings: {
              ...prev.warnings,
              differentVariants: !isSameVariants ? 'Variants of your main product and component are different' : '',
            },
          })),
        );
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  public static checkDifferentConfigurations() {
    return async (dispatch, getState: () => AppState) => {
      const { products } = getState().production.productsLaunchModal;
      const mainProductConfiguration = products[0].configuration?.name;

      const productsMapped = products.slice(1).map((i) => ({ name: i.configuration?.name || '' }));
      const isSameConfigurations = productsMapped.length
        ? checkAllValuesIsSimilar(mainProductConfiguration, productsMapped)
        : true;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          warnings: {
            ...prev.warnings,
            differentConfigurations: !isSameConfigurations
              ? 'Configurations of your main product and component are different'
              : '',
          },
        })),
      );
    };
  }

  public static onChangeWorkflowTemplate(id: string, value: ProductForProductionWorkflow, productVariantId: string) {
    return (dispatch, getState: GetStateFunction) => {
      const { products } = getState().production.productsLaunchModal;
      dispatch(ProductsLaunchModalActions.deleteNestedWorkflows(id, productVariantId));
      const product = products.find((item) => item.id === id);
      if (product?.workflowType === ProductionWorkflowType.workflowTemplate) {
        dispatch(ProductsLaunchModalActions.initNestedProducts(value.id, id, productVariantId));
      }
    };
  }

  public static deleteNestedWorkflows(id: string, productVariantId: string = '') {
    return (dispatch, getState: GetStateFunction) => {
      const { products } = getState().production.productsLaunchModal;
      let allNestedProducts: ProductForLaunch[] = [];
      const findNestedProducts = (nestedId: string) => {
        const nestedProducts = products.filter((item) => item.nested_for === nestedId);
        allNestedProducts = allNestedProducts.concat(nestedProducts);
        if (nestedProducts.length > 0) {
          nestedProducts.forEach((item) => findNestedProducts(item.id));
        }
      };
      findNestedProducts(id);
      const nestedProductsIds = allNestedProducts.reduce((result, item) => {
        // eslint-disable-next-line no-param-reassign
        result[item.id] = { ...item };
        return result;
      }, {});
      const newProducts = products.filter((item) => !nestedProductsIds[item.id]);
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          products: newProducts,
          groupedProductions: prev.groupedProductions.map((production) => {
            return production.variant.id === productVariantId
              ? { ...production, configuredProductions: newProducts }
              : production;
          }),
        })),
      );
      dispatch(ProductsLaunchModalActions.checkContainsItself());
    };
  }

  public static onChangeWorkflowType(id: string, value: ProductionWorkflowType | '', productVariantId: string | undefined) {
    return (dispatch, getState: GetStateFunction) => {
      try {
        const { products } = getState().production.productsLaunchModal;
        const product = products.find((item) => item.id === id);

        if (value === ProductionWorkflowType.workflowTemplate && product?.workflowTemplate) {
          dispatch(ProductsLaunchModalActions.initNestedProducts(product.workflowTemplate.id, id, productVariantId));
        } else {
          dispatch(ProductsLaunchModalActions.deleteNestedWorkflows(id, productVariantId));
        }
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  public static openLaunchProductModal() {
    return async (dispatch, getState: GetStateFunction) => {
      const { products } = getState().production.productsLaunchModal;
      const productForLaunch: ProductForLaunchPreview[] = products
        .filter(
          (item) =>
            item.status === ProductionStatusEnum.To_Do &&
            (item.workflowType === ProductionWorkflowType.warehouse ||
              (item.workflowType === ProductionWorkflowType.workflowTemplate && item.workflowTemplate)),
        )
        .map((item) => ({
          id: item.id,
          productName: item.product_name,
          version: item.version?.name || '',
          configuration: item.configuration?.name || '',
          variant: item.variant?.name || '',
          workflow:
            item.workflowType === ProductionWorkflowType.workflowTemplate ? item.workflowTemplate?.name || '' : 'From stock',
        }));
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          launchProductModal: {
            isOpen: true,
            products: productForLaunch,
          },
        })),
      );
    };
  }

  public static closeLaunchProductModal() {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          launchProductModal: defaultState.launchProductModal,
        })),
      );
    };
  }

  private static launch() {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        const { products } = getState().production.productsLaunchModal;

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            launchProductModal: {
              ...prev.launchProductModal,
              isLaunching: true,
            },
          })),
        );
        const filteredProducts = products.filter(
          (item) =>
            item.status === ProductionStatusEnum.To_Do &&
            (item.workflowType === ProductionWorkflowType.warehouse ||
              (item.workflowType === ProductionWorkflowType.workflowTemplate && item.workflowTemplate)),
        );

        const maxPosition = filteredProducts.reduce((min, current) => {
          return current.position < min ? current.position : min;
        }, 100);

        const productsForLaunch = filteredProducts.filter((item) => item.position === maxPosition);

        const mapDataForLaunch = (product: ProductForLaunch): ProductionWorkflowLaunchData => ({
          product_variant_id: product.variant?.id || '',
          ...(product.workflowType ? { type: product.workflowType } : {}),
          ...(product.id.includes('new') ? {} : { production_workflow_id: product.id }),
          ...(product.workflowTemplate && product.workflowType ? { workflow_template_id: product.workflowTemplate.id } : {}),
          ...(product.workflow_template_item_id ? { workflow_template_item_id: product.workflow_template_item_id } : {}),
          ...(product.comment ? { comment: product.comment } : {}),
          nested_workflows: products.filter((item) => item.nested_for === product.id).flatMap((prod) => mapDataForLaunch(prod)),
        });

        const data = productsForLaunch.map((item) => mapDataForLaunch(item));

        await ProductionWorkflowService.launch(data);
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  static handleProductionLaunch = () => {
    return async (dispatch, getState: GetStateFunction) => {
      const { openedFrom, productionIdToLoadDataFor } = getState().production.productsLaunchModal;

      await dispatch(ProductsLaunchModalActions.launch());

      if (
        openedFrom === LocationTheProductionStatusIsChangingFrom.ProductionList ||
        openedFrom === LocationTheProductionStatusIsChangingFrom.NewProductionModal
      ) {
        dispatch(ProductionFiltersActions.getProductionsByFilter({ resetSkipPreserveTake: true }));
      }

      if (openedFrom === LocationTheProductionStatusIsChangingFrom.WorkflowItemAdditionalProduction) {
        await dispatch(ProductionWorkflowActions.silentLoad({ id: productionIdToLoadDataFor }));
      }

      if (
        openedFrom === LocationTheProductionStatusIsChangingFrom.WorkflowHeader ||
        openedFrom === LocationTheProductionStatusIsChangingFrom.WorkflowItem
      ) {
        dispatch(ProductionWorkflowActions.initWorkflowPage(productionIdToLoadDataFor));
      }

      notify.success('Workflow has been launched');
      dispatch(stateController.setState(defaultState));
    };
  };

  public static closeModal() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  // =================================================== MULTI LAUNCH ==========================================================================

  public static openMultiLaunchModal(
    selectedProductions: ProductionWorkflow[],
    openedFrom: LocationTheProductionStatusIsChangingFrom,
  ) {
    return async (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductionEdit, [AccessLevel.access]))) {
        return;
      }
      const groupedAndSortedProductions = groupProductionsForMultiLaunch(selectedProductions);
      const firstProduction = groupedAndSortedProductions[0];
      const productionId = firstProduction.id;
      const productVariantId = firstProduction.variant.id;

      await dispatch(
        stateController.setState((prev) => ({
          ...prev,
          openedFrom,
          isOpen: true,
          groupedProductions: groupedAndSortedProductions,
          primaryProductVariantId: firstProduction.variant.id,
        })),
      );
      dispatch(ProductsLaunchModalActions.initProductsWithVersions(productionId, productVariantId, true));
    };
  }

  public static handleNavigationBetweenProducts = ({ index, direction }: HandleBackAndNextPressArgs) => {
    return async (dispatch, getState: () => AppState) => {
      const { currentProductionIndex, groupedProductions } = getState().production.productsLaunchModal;

      const productionToDisplayIndex = index ?? (direction === 'back' ? currentProductionIndex - 1 : currentProductionIndex + 1);

      const productionToDisplay = groupedProductions[productionToDisplayIndex];
      const configuredProduct = groupedProductions.find(
        (production) => production.variant.id === productionToDisplay.variant.id,
      )?.configuredProductions;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          currentProductionIndex: productionToDisplayIndex,
          ...(configuredProduct?.length ? { products: configuredProduct } : {}),
        })),
      );

      if (!configuredProduct?.length) {
        await dispatch(
          ProductsLaunchModalActions.initProductsWithVersions(productionToDisplay.id, productionToDisplay.variant.id, true),
        );
      }

      dispatch(ProductsLaunchModalActions.checkDifferentVariants());
      dispatch(ProductsLaunchModalActions.checkDifferentConfigurations());
      dispatch(ProductsLaunchModalActions.checkContainsItself());
    };
  };

  public static removeProductionGroupFromMultiLaunch = (group: GroupedProductions, index: number) => {
    return (dispatch, getState: () => AppState) => {
      const { currentProductionIndex, groupedProductions } = getState().production.productsLaunchModal;
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          groupedProductions: prev.groupedProductions.filter((groupProduction) => groupProduction.id !== group.id),
        })),
      );

      if (index === currentProductionIndex) {
        let followingIndex = currentProductionIndex;

        if (index === groupedProductions.length - 1) {
          followingIndex = groupedProductions.length - 2;
        }

        dispatch(ProductsLaunchModalActions.handleNavigationBetweenProducts({ index: followingIndex }));
      }

      dispatch(ProductionListActions.handleSelectDeselectProductions(group.data));
    };
  };

  public static openLaunchProductModalFromMultiLaunch() {
    return async (dispatch, getState: GetStateFunction) => {
      const { groupedProductions } = getState().production.productsLaunchModal;
      const productForLaunch: ProductForLaunchPreview[] = groupedProductions.map((item) => {
        const mainProduction = item.configuredProductions[0];

        return {
          id: item.id,
          version: item.version,
          units: item.data.length,
          variant: item.variant.name,
          productName: item.product_name,
          configuration: item.configuration,
          workflow:
            mainProduction.workflowType === ProductionWorkflowType.workflowTemplate
              ? mainProduction.workflowTemplate?.name || ''
              : 'From stock',
        };
      });

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          launchProductModal: {
            isOpen: true,
            products: productForLaunch,
          },
        })),
      );
    };
  }

  public static multiLaunch() {
    return async (dispatch, getState: GetStateFunction) => {
      const state = getState();
      const { production } = state;
      const { groupedProductions } = production.productsLaunchModal;

      const productionIdsForLaunch = groupedProductions.flatMap((group) => group.data.map((item) => item.id));

      dispatch(
        ProductionsLaunchingProgressActions.showModalAndSetLaunchingProductionsCount(
          ProductsLaunchModalSelectors.getAllToLaunchCount(state),
        ),
      );
      dispatch(stateController.setState(defaultState));
      dispatch(ProductionListActions.handleLaunchingProductionIds({ value: productionIdsForLaunch }));

      const data = groupedProductions.flatMap((group) => {
        const filteredProducts = group.configuredProductions.filter(
          (item) =>
            item.status === ProductionStatusEnum.To_Do &&
            (item.workflowType === ProductionWorkflowType.warehouse ||
              (item.workflowType === ProductionWorkflowType.workflowTemplate && item.workflowTemplate)),
        );

        const maxPosition = filteredProducts.reduce((min, current) => {
          return current.position < min ? current.position : min;
        }, 100);

        const productsForLaunch = filteredProducts.filter((item) => item.position === maxPosition);

        const mapDataForLaunch = (product: ProductForLaunch): ProductionWorkflowMultiLaunchData => ({
          product_variant_id: product.variant?.id || '',
          ...(product.workflowType ? { type: product.workflowType } : {}),
          ...(product.id.includes('new') ? {} : { production_workflow_id: group.data.map((item) => item.id) }),
          ...(product.workflowTemplate && product.workflowType ? { workflow_template_id: product.workflowTemplate.id } : {}),
          ...(product.workflow_template_item_id ? { workflow_template_item_id: product.workflow_template_item_id } : {}),
          ...(product.comment ? { comment: product.comment } : {}),
          nested_workflows: group.configuredProductions
            .filter((item) => item.nested_for === product.id)
            .flatMap((prod) => mapDataForLaunch(prod)),
        });

        return productsForLaunch.map((item) => mapDataForLaunch(item));
      });

      try {
        await ProductionWorkflowService.multiLaunch(data);
      } catch (error) {
        dispatch(
          ProductionListActions.handleLaunchingProductionIds({
            value: productionIdsForLaunch,
            removeLaunchedProductionIds: true,
          }),
        );
        dispatch(ProductionsLaunchingProgressActions.hideModal());
        notify.error(error);
      }
    };
  }
}

export class ProductsLaunchModalSelectors {
  public static getAllToLaunchCount(state: AppState) {
    const { groupedProductions } = state.production.productsLaunchModal;

    return groupedProductions.reduce((acc, currentValue) => {
      return acc + currentValue.data.length;
    }, 0);
  }

  public static getLaunchedCount(state: AppState) {
    return state.production.productsLaunchModal.products.filter(
      (item) => item.status !== ProductionStatusEnum.To_Do && item.status !== ProductionStatusEnum.From_Stock,
    ).length;
  }

  public static getFromWarehouseCount(state: AppState) {
    return state.production.productsLaunchModal.products.filter((item) => item.status === ProductionStatusEnum.From_Stock).length;
  }

  public static getReadyToLaunchCount(state: AppState) {
    const { groupedProductions, nestedFetchingIds, products } = state.production.productsLaunchModal;

    if (groupedProductions.length) {
      const configuredProductions = groupedProductions.filter((production) => {
        const mainProduction = production.configuredProductions[0];
        const isMainProductionFetching = nestedFetchingIds.some((id) => mainProduction?.id === id);
        const isMainToProduction = mainProduction?.workflowType === ProductionWorkflowType.workflowTemplate;
        const areSomeComponentsNotConfigured = checkIfNotAllElementsConfigured(production.configuredProductions);

        return !isMainProductionFetching && isMainToProduction && !areSomeComponentsNotConfigured;
      });

      return configuredProductions.reduce((acc, currentValue) => {
        return acc + currentValue.data.length;
      }, 0);
    }

    return products.filter(
      (item) => item.workflowType === ProductionWorkflowType.workflowTemplate && checkIfProductReadyToLaunch(item),
    ).length;
  }

  public static getReadyTakeFromWarehouseCount(state: AppState) {
    const { groupedProductions } = state.production.productsLaunchModal;

    if (groupedProductions.length) {
      const configuredProductions = groupedProductions.filter((production) => {
        const mainProduction = production.configuredProductions[0];
        const isMainFromWarehouse = mainProduction?.workflowType === ProductionWorkflowType.warehouse;
        const areSomeComponentsNotConfigured = checkIfNotAllElementsConfigured(production.configuredProductions);

        return isMainFromWarehouse && !areSomeComponentsNotConfigured;
      });

      return configuredProductions.reduce((acc, currentValue) => {
        return acc + currentValue.data.length;
      }, 0);
    }

    return state.production.productsLaunchModal.products.filter(
      (item) => item.workflowType === ProductionWorkflowType.warehouse && checkIfProductReadyToLaunch(item),
    ).length;
  }

  public static getIsLaunchMode(state: AppState) {
    return (
      !!state.production.productsLaunchModal.groupedProductions.length ||
      state.production.productsLaunchModal.products.some((item) => item.status === ProductionStatusEnum.To_Do)
    );
  }

  public static getIsLaunchButtonDisabled(state: AppState) {
    const { products, groupedProductions, nestedFetchingIds } = state.production.productsLaunchModal;

    if (groupedProductions.length) {
      return (
        nestedFetchingIds.some((id) => groupedProductions.some((group) => group.id === id)) ||
        groupedProductions.some((group) => checkIfNotAllElementsConfigured(group.configuredProductions))
      );
    }

    return checkIfNotAllElementsConfigured(products);
  }
}

export const reducer = stateController.getReducer();
