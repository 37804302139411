import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { IdName } from 'types/common-types';

export const returnParams = (values: IdName[], isGetName?: boolean) => {
  let valuesToReturn: string[] = [];
  if (isGetName) valuesToReturn = values.map((value) => value.name);

  if (!isGetName) valuesToReturn = values.map((value) => value.id);

  return valuesToReturn.length ? valuesToReturn : undefined;
};

export const convertToStockFilterParams = (values: IdName[]): boolean | undefined => {
  const hasYes = values.some((value) => value.name === 'Yes');
  const hasNo = values.some((value) => value.name === 'No');

  switch (true) {
    case hasYes && hasNo:
      return undefined;

    case hasYes:
      return true;

    case hasNo:
      return false;

    default:
      return undefined;
  }
};

export const convertToStockFilterOptions = (value: boolean | undefined, options: IdName[]): IdName[] => {
  if (value === true) {
    const yesOption = options.find((option) => option.name === 'Yes');
    return yesOption ? [yesOption] : [];
  }
  if (value === false) {
    const noOption = options.find((option) => option.name === 'No');
    return noOption ? [noOption] : [];
  }
  return [];
};

const mapArrayToObjectById = (array: IdName[]) => {
  return array.reduce((result, item) => {
    const res = { ...result };
    res[item.id] = { ...item };
    return res;
  }, {});
};

export const getValueFromIds = (values: string[] | undefined, options: IdName[]): IdName[] => {
  if (values) {
    const optionsMap = mapArrayToObjectById(options);
    return values.map((item) => optionsMap[item]);
  }
  return [];
};

export const getValues = (values: IdName[] | undefined): IdName[] => {
  if (values) {
    return values.map((i) => ({
      id: i.id === null ? 'null' : i.id,
      name: i.name,
    }));
  }
  return [];
};

export const getDatesForFilters = (value: DateRange<Dayjs>): [string | null, string | null] | undefined => {
  const from = value[0];
  const to = value[1];

  const start = from && dayjs(from).isValid() ? from.startOf('day').toISOString() : null;
  const end = to && dayjs(to).isValid() ? to.endOf('day').toISOString() : null;

  return start !== null || end !== null ? [start, end] : undefined;
};

export const stringArrayToDateRange = (dateStrings: string[]): DateRange<Dayjs> => {
  if (!!dateStrings[0] && !dateStrings[1]) {
    const singleDate = dayjs(dateStrings[0]);
    return [singleDate, null];
  }
  if (!dateStrings[0] && !!dateStrings[1]) {
    const singleDate = dayjs(dateStrings[1]);
    return [null, singleDate];
  }
  const startDate = dayjs(dateStrings[0]);
  const endDate = dayjs(dateStrings[1]);
  return [startDate, endDate];
};

export const getIdName = (values: { id: string; name: string; [key: string]: any }[]) => {
  const valuesToReturn = values.map((i) => ({
    id: (i.id === 'null' ? null : i.id) as string,
    name: i.name,
  }));
  return valuesToReturn.length ? valuesToReturn : undefined;
};

export const getIdFullName = (items: { first_name: string; last_name: string; id: string; [key: string]: any }[]) => {
  return items.map((item) => ({ ...item, name: `${item.first_name} ${item.last_name}` }));
};

export const getDepartmentPath = (items: { path: IdName[]; id: string; name: string; [key: string]: any }[]) => {
  return items.map((item) => ({ ...item, path: item.path.map((i) => i.name).join('/') }));
};
