import { Stack } from '@mui/material';
import { AppState } from 'redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { TasksActions } from 'pages/tasks/tasks.controller';
import { AccessLevel, Permission } from 'services/permission.model';
import StatusSelector from 'components/status-selector/status-selector';
import { PermissionGuardSelectors } from 'modules/permission-guard/permission-guard.controller';
import { TaskStatusEnum } from 'types/status-enums';
import { ICellRendererParams } from 'ag-grid-community';
import { TaskTableModel } from 'services/task-table.model';

type Props = ICellRendererParams<TaskTableModel>;

const TaskStatusCell = ({ data }: Props) => {
  if (!data) return null;

  const dispatch = useDispatch();
  const taskDepartments = data?.department_ids.map((i: string) => ({ id: i })) || [];
  const accessLevels = [AccessLevel.userDepartment, AccessLevel.userDepartmentSubDepartments, AccessLevel.allDepartments];
  const isPermitted = useSelector((state: AppState) =>
    PermissionGuardSelectors.checkEditLevel(state, Permission.webTaskEditLevel, accessLevels, taskDepartments),
  );
  const isAllowedUndoChangingTaskStatus = useSelector((state: AppState) => state.settings.isAllowedUndoChangingTaskStatus);
  const isAllowedChangingTaskStatus = isAllowedUndoChangingTaskStatus && !data.is_reporting_period_closed;

  return (
    <Stack justifyContent="center" alignItems="center" width="100%" height="100%">
      <StatusSelector
        optionsFor="task"
        status={data.status}
        isEditPermitted={isPermitted}
        productionStatus={data?.production_status}
        isBlockedLaunch={data.status === TaskStatusEnum.Done && !isAllowedChangingTaskStatus}
        onSelect={async (selectedStatus) => {
          dispatch(TasksActions.updateTask(data.id, { status: selectedStatus }));
        }}
      />
    </Stack>
  );
};

export default TaskStatusCell;
