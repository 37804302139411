import { router } from 'index';
import { notify } from 'notifications';
import { AppState } from 'redux/store';
import { Paths } from 'routes/paths';
import { IdName } from 'types/common-types';
import { Actions as DepartmentsActions } from 'pages/departments/departments.controller';
import { PositionSlotByDepartmentId as PositionItem } from 'services/position-slots.model';
import { PositionTypesService } from 'services/position-types.service';
import { PositionSlotsService } from 'services/position-slots.service';
import { UserService } from 'services/user.service';
import { StateController } from 'state-controller';
import { noUser } from 'components/add-position-modal/constants/no-user';

export enum Page {
  Departments = 'departments',
}

export enum Mode {
  Edit = 'edit',
  Add = 'add',
}

export type InputItem = {
  value: IdName;
  options: IdName[];
  isDisabled: boolean;
};
export type EmployeeItem = {
  id: string;
  name: string;
  avatar: string;
  lastName: string;
  firstName: string;
};
export type EmployeeInputItem = {
  value: EmployeeItem;
  options: EmployeeItem[];
  isDisabled: boolean;
};

export type AddPositionModalState = {
  isLoading: boolean;
  isModalOpen: boolean;
  isProcessing: boolean;
  isValueChanged: boolean;
  mode: Mode;
  positionId: string;
  jobTitle: InputItem;
  employee: EmployeeInputItem;
  positionTitle: InputItem;
};

const defaultState: AddPositionModalState = {
  isLoading: false,
  isModalOpen: false,
  isProcessing: false,
  isValueChanged: false,
  mode: Mode.Add,
  positionId: '',
  jobTitle: {
    value: { id: '', name: '' },
    options: [],
    isDisabled: false,
  },
  employee: {
    value: { id: '', name: '', avatar: '', firstName: '', lastName: '' },
    options: [],
    isDisabled: false,
  },
  positionTitle: {
    value: { id: '', name: '' },
    options: [],
    isDisabled: false,
  },
};

const stateController = new StateController<AddPositionModalState>('ADD_POSITION_MODAL_STATE', defaultState);

export class Actions {
  public static loadData() {
    return async (dispatch) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            isLoading: true,
            jobTitle: {
              ...prev.jobTitle,
              isDisabled: true,
            },
            employee: {
              ...prev.employee,
              isDisabled: true,
            },
            positionTitle: {
              ...prev.positionTitle,
              isDisabled: true,
            },
          })),
        );

        const users = await UserService.getUsersWithoutPosition();
        const positions = await PositionTypesService.getPositionTypes('', false);
        const descriptions = await PositionSlotsService.descriptionsByPositionType();

        const usersMapped = [noUser, ...users].map((item) => ({
          id: item.id,
          lastName: item.last_name,
          firstName: item.first_name,
          avatar: item.avatar_image_url,
          name: `${item.first_name} ${item.last_name}`,
        }));

        const positionsMapped = positions
          .filter((pos) => pos.name !== '')
          .map((item) => ({
            name: item.name,
            id: item.id,
          }));
        const descriptionsMapped = descriptions
          .filter((des) => des !== '')
          .map((item, index) => ({
            name: item,
            id: `${item}-${index}`,
          }));

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            jobTitle: {
              ...prev.jobTitle,
              options: positionsMapped,
            },
            positionTitle: {
              ...prev.positionTitle,
              options: descriptionsMapped,
            },
            employee: {
              ...prev.employee,
              value: prev.mode === Mode.Add ? noUser : prev.employee.value,
              options: usersMapped,
            },
          })),
        );

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            jobTitle: {
              ...prev.jobTitle,
              isDisabled: false,
            },
            employee: {
              ...prev.employee,
              isDisabled: false,
            },
            positionTitle: {
              ...prev.positionTitle,
              isDisabled: false,
            },
          })),
        );
      } catch (err) {
        notify.error('Something went wrong');
        throw err;
      } finally {
        dispatch(stateController.setState({ isLoading: false }));
      }
    };
  }

  public static openModal(item?: PositionItem) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isModalOpen: true,
          mode: item ? Mode.Edit : Mode.Add,
        })),
      );

      if (item) {
        const user = item.users[0] || noUser;
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            positionId: item.id,
            jobTitle: {
              ...prev.jobTitle,
              value: {
                id: item.positionType.id,
                name: item.positionType.name,
              },
            },
            positionTitle: {
              ...prev.positionTitle,
              value: {
                id: 'positionTitle',
                name: item.name,
              },
            },
            employee: {
              ...prev.employee,
              value: user.id
                ? {
                    id: user.id,
                    name: `${user.first_name} ${user.last_name}`,
                    avatar: user?.avatar_image_url,
                    lastName: user.last_name,
                    firstName: user.first_name,
                  }
                : noUser,
            },
          })),
        );
      }

      dispatch(Actions.loadData());
    };
  }

  public static closeModal() {
    return (dispatch) => {
      dispatch(stateController.setState(defaultState));
    };
  }

  public static onChangeJobTitleValue(value: IdName) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isValueChanged: true,
          jobTitle: {
            ...prev.jobTitle,
            value,
          },
        })),
      );
    };
  }

  public static onChangePositionTitleValue(value: IdName) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isValueChanged: true,
          positionTitle: {
            ...prev.positionTitle,
            value,
          },
        })),
      );
    };
  }

  public static onChangeEmployeeValue(value: EmployeeItem) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isValueChanged: true,
          employee: {
            ...prev.employee,
            value,
          },
        })),
      );
    };
  }

  public static onConfirmClick(page: Page) {
    return async (dispatch, getState: () => AppState) => {
      const { mode } = getState().addPositionModal;

      if (mode === Mode.Add) dispatch(Actions.addPosition(page));
      if (mode === Mode.Edit) dispatch(Actions.updatePosition(page));
    };
  }

  public static addPosition(page: Page) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isProcessing: true }));

        const { jobTitle, employee, positionTitle } = getState().addPositionModal;
        const { breadcrumbs } = getState().departments;
        const departmentId = breadcrumbs[breadcrumbs.length - 1].id;

        let positionType = {};

        if (jobTitle.value.id !== 'new') {
          positionType = { id: jobTitle.value.id, name: jobTitle.value.name };
        } else {
          positionType = { name: jobTitle.value.name, id: null };
        }

        const body = {
          position_type: positionType,
          department_id: departmentId,
          user_id: employee.value.id || undefined,
          position_slot_name: positionTitle?.value?.name || '',
        };

        const data = await PositionSlotsService.createWithAssignment(body);

        if (page === Page.Departments) {
          await dispatch(DepartmentsActions.loadData(departmentId, false));
        }

        dispatch(Actions.closeModal());
        dispatch(DepartmentsActions.setLastAddedItemId(data.id));
      } catch {
        notify.error('Something went wrong');
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }

  public static updatePosition(page: Page) {
    return async (dispatch, getState: () => AppState) => {
      try {
        dispatch(stateController.setState({ isProcessing: true }));

        const { jobTitle, employee, positionTitle } = getState().addPositionModal;
        const { breadcrumbs } = getState().departments;
        const departmentId = breadcrumbs[breadcrumbs.length - 1].id;
        const { positionId } = getState().addPositionModal;

        let positionType = {};

        if (jobTitle.value.id !== 'new') {
          positionType = { id: jobTitle.value.id, name: jobTitle.value.name };
        } else {
          positionType = { name: jobTitle.value.name, id: null };
        }

        const body = {
          id: positionId,
          position_type: positionType,
          department_id: departmentId,
          user_id: employee.value.id || undefined,
          position_slot_name: positionTitle?.value?.name || '',
        };

        const data = await PositionSlotsService.updateWithAssignment(body);

        if (page === Page.Departments) {
          await dispatch(DepartmentsActions.loadData(departmentId, false));
        }

        dispatch(Actions.closeModal());
        dispatch(DepartmentsActions.setLastAddedItemId(data.id));
      } catch {
        notify.error('Something went wrong');
      } finally {
        dispatch(stateController.setState({ isProcessing: false }));
      }
    };
  }

  public static manageUserPosition(departmentId: string) {
    return async (dispatch, getState: () => AppState) => {
      const { user } = getState();

      if (departmentId) {
        const position = getState().user.positions[0];
        const slotId = position?.positionSlotId;
        const slotName = position?.positionSlotName;
        const positionTypeId = position?.positionTypeId;
        const positionTypeName = position?.positionTypeName;

        const item = {
          id: slotId,
          name: slotName,
          positionType: {
            description: '',
            id: positionTypeId,
            name: positionTypeName,
          },
          users: [
            {
              avatar_image_url: user.avatar_image_url,
              first_name: user.first_name,
              id: user.id,
              last_name: user.last_name,
            },
          ],
        };

        router.navigate(`${Paths.Departments}/${departmentId}`);
        dispatch(Actions.openModal(item));
      } else {
        const employee = {
          id: user.id,
          lastName: user.last_name,
          firstName: user.first_name,
          avatar: user.avatar_image_url,
          name: `${user.first_name} ${user.last_name}`,
        };

        router.navigate(`${Paths.Departments}`);
        dispatch(Actions.openModal());
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            employee: {
              ...prev.employee,
              value: employee,
            },
          })),
        );
      }
    };
  }
}

export class Selectors {
  public static isConfirmButtonDisabled(state: AppState) {
    const jobTitle = state.addPositionModal.jobTitle.value;
    const employee = state.addPositionModal.employee.value;
    const { isValueChanged } = state.addPositionModal;
    return !isValueChanged || !jobTitle || !employee;
  }
}

export const reducer = stateController.getReducer();
