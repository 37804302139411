/* eslint-disable no-restricted-syntax */
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import { ProductionIssueEnum, ProductionWorkflow, ProductionWorkflowResponseDataT } from 'services/production-workflow.model';
import { IdName } from 'types/common-types';

export const getValidDateValues = (value?: DateRange<Dayjs>): DateRange<Dayjs> => {
  const validValue = value?.map((item) => {
    const date = dayjs(item);
    return date.isValid() ? date : null;
  }) as DateRange<Dayjs>;

  return validValue;
};

export const getProductionIssues = (production: ProductionWorkflow) => {
  const issues: IdName[] = [];
  if (!production.variant.id) {
    issues.push({
      id: ProductionIssueEnum.UndefinedProduct,
      name: `Undefined product from ${production.order.external_system_name}. Barcode ${production.barcode || '-'}`,
    });
  }
  if (production.deadline_at && new Date(production.deadline_at) < new Date()) {
    issues.push({
      id: ProductionIssueEnum.ProductionDeadlineExpired,
      name: 'The deadline has been reached',
    });
  }
  if (production.is_manual_assignmet_required) {
    issues.push({
      id: ProductionIssueEnum.TasksRequiringManualAssignment,
      name: 'Manual assignment for a task required',
    });
  }
  if (production.is_any_task_time_limit_exceeded) {
    issues.push({
      id: ProductionIssueEnum.TaskTimeLimitExceeded,
      name: 'The task tracker time has exceeded the task time limit',
    });
  }
  if (production.nested_production_component_has_issues || production.additional_component_has_issue) {
    issues.push({
      id: ProductionIssueEnum.IssuesInNestedComponents,
      name: 'Nested production component has an issue',
    });
  }

  return issues;
};

export const getDefaultSimilarValue = (
  targetName: string | null | undefined,
  valuesArray: { name: string; [key: string]: any }[] = [],
): any => {
  const caseSensitiveMatch = valuesArray?.find((i) => i.name === targetName);
  if (caseSensitiveMatch) {
    return caseSensitiveMatch;
  }
  return null;
};

export const getDefaultVariantValue = (
  targetName: string | null | undefined,
  valuesArray: { name: string; [key: string]: any }[],
  isStrictMode?: boolean,
): any => {
  if (targetName && valuesArray?.length) {
    const targetNamesArray = targetName.split(' / ');
    for (const item of valuesArray) {
      const valueNamesArray = item.name?.split(' / ');
      if (targetNamesArray.slice().sort().join('/') === valueNamesArray.slice().sort().join('/')) {
        return item;
      }
    }

    const targetNamesLowerCase = targetNamesArray.map((i) => i.toLowerCase());
    for (const item of valuesArray) {
      const valueNamesArray = item.name?.split(' / ').map((i) => i.toLowerCase());
      if (targetNamesLowerCase.slice().sort().join('/') === valueNamesArray.slice().sort().join('/')) {
        return item;
      }
    }

    if (!isStrictMode) {
      for (const item of valuesArray) {
        const valueNamesArray = item.name?.split(' / ').map((i) => i.toLowerCase());
        if (targetNamesLowerCase.some((name) => valueNamesArray.includes(name))) {
          return item;
        }
      }
    }
  }

  return null;
};

export const checkAllValuesIsSimilar = (
  targetName: string | null | undefined,
  valuesArray: { name: string; [key: string]: any }[] = [],
): any => {
  if (!valuesArray?.length) return false;

  return valuesArray.every((item) => {
    return item.name === targetName;
  });
};

export const checkAllVariantsIsSimilar = (targetName: string, valuesArray: { name: string; [key: string]: any }[]): boolean => {
  if (targetName) {
    const targetNamesArray = targetName.split(' / ').map((i) => i.toLowerCase());

    if (!valuesArray?.length) return false;

    return valuesArray.every((item) => {
      const valueNamesArray = item.name?.split(' / ').map((i) => i.toLowerCase());
      return targetNamesArray.slice().sort().join('/') === valueNamesArray?.slice().sort().join('/');
    });
  }

  return false;
};

export const checkIfPlaneView = (productionItems: ProductionWorkflowResponseDataT): productionItems is ProductionWorkflow[] => {
  return productionItems[0] && !('production_workflows' in productionItems[0]);
};
