import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const formatDate = (date: string) => dayjs(date).utc().format('DD.MM.YYYY');

const ReportingPeriodCell = (params) => {
  const { date_from, date_to } = params?.value || {};
  if (!date_from || !date_to) return null;
  const periodString = `${formatDate(date_from)} - ${formatDate(date_to)}`;
  return <Stack>{periodString}</Stack>;
};

export default ReportingPeriodCell;
