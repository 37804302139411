import { baseAxiosInstance } from 'axios-config';
import { PositionType } from 'services/position-types.model';

export class PositionTypesService {
  public static async getPositionTypes(search?: string, user_count?: boolean, department_id?: any) {
    const { data } = await baseAxiosInstance.get<PositionType[]>('position-type/all', {
      params: {
        search,
        user_count,
        department_id,
      },
    });

    return data;
  }
}
