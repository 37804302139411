import { FC, SVGProps } from 'react';

export const ChainIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
    <path d="M9.41406 7.08575L6.58564 9.91418" stroke="#141414" strokeLinecap="round" />
    <path
      d="M6.8893 5.16734L8.00042 4.05622C9.22773 2.82891 11.2176 2.82891 12.4449 4.05622V4.05622C13.6722 5.28353 13.6722 7.27339 12.4449 8.50069L11.3338 9.61181"
      stroke="#141414"
      strokeLinecap="round"
    />
    <path
      d="M9.1107 11.8327L7.99958 12.9438C6.77227 14.1711 4.78241 14.1711 3.55511 12.9438V12.9438C2.3278 11.7165 2.3278 9.72661 3.55511 8.49931L4.66623 7.38819"
      stroke="#141414"
      strokeLinecap="round"
    />
  </svg>
);
