import { baseAxiosInstance } from 'axios-config';
import { TAKE_ITEMS } from 'constants/take-items';
import { PaginatedResponse } from 'types/common-types';
import { ProductType } from 'services/product-type.model';

export class ProductTypeService {
  public static async getAllProductTypes(search = '', skip = 0, take = TAKE_ITEMS) {
    const { data } = await baseAxiosInstance.get<PaginatedResponse<ProductType[]>>('/product-types/all', {
      params: {
        search,
        skip,
        take,
      },
    });
    return data;
  }

  public static async createProductType(name: string) {
    const { data } = await baseAxiosInstance.post<ProductType>('/product-types/create', { name });
    return data;
  }

  public static async deleteProductType(productTypeId: string) {
    return baseAxiosInstance.delete(`/product-types/${productTypeId}`);
  }
}
