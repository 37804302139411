// Notice! Do not forget to add path to proper array (NonAuthorized / Common / Authorized)
export enum Paths {
  Analytics = '/pbi-analytics',
  Components = '/components',
  CreatePassword = '/create-new-password',
  CompanyAccessDenied = '/company-access-denied',
  Departments = '/departments',
  ForgotPassword = '/forgot-password',
  Home = '/',
  Icons = '/icons',
  InvalidLink = '/invalid-link',
  LabelWithQr = '/label-with-qr',
  NoPermission = '/no-permission',
  NotFound = '/404',
  Organization = '/organization',
  Product = '/product',
  Products = '/products',
  ProductSearch = '/product-search',
  Production = '/production',
  ProductionWorkflow = '/production-workflow',
  ProductionTasksList = '/production-tasks-list',
  Settings = '/settings',
  SelectCompany = '/select-company',
  SignIn = '/sign-in',
  SignUp = '/sign-up',
  Task = '/task',
  Tasks = '/tasks',
  TaskTemplate = '/task-template',
  User = '/user',
  Users = '/users',
  WorkflowEditor = '/workflow-editor',
  WorkFlowTemplate = '/workflow-template',
}

export const NonAuthorizedPaths: string[] = [Paths.SignIn, Paths.SignUp];

export const isNonAuthorizedPath = () => NonAuthorizedPaths.some((path) => isPathMatch(path));

export const CommonPaths: string[] = [
  Paths.CreatePassword,
  Paths.ForgotPassword,
  Paths.InvalidLink,
  Paths.NotFound,
  Paths.Icons,
  Paths.Components,
];

export const isCommonPath = () => CommonPaths.some((path) => isPathMatch(path));

export const CompanyPaths: string[] = [
  Paths.Analytics,
  Paths.CompanyAccessDenied,
  Paths.Departments,
  Paths.Home,
  Paths.InvalidLink,
  Paths.NoPermission,
  Paths.Organization,
  Paths.Product,
  Paths.Products,
  Paths.ProductSearch,
  Paths.Production,
  Paths.ProductionWorkflow,
  Paths.Settings,
  Paths.SelectCompany,
  Paths.Task,
  Paths.TaskTemplate,
  Paths.Tasks,
  Paths.User,
  Paths.Users,
  Paths.WorkflowEditor,
  Paths.WorkFlowTemplate,
];

export const isCompanyPath = () => CompanyPaths.some((path) => isPathMatch(path));

function isPathMatch(path: string) {
  const pathWithQuery = `${path}?`;
  const pathWithNestedPath = `${path}/`;
  const isMatch =
    window.location.pathname === path ||
    window.location.pathname.includes(pathWithQuery) ||
    window.location.pathname.includes(pathWithNestedPath);

  return isMatch;
}
