import { UpdatedTaskResponsibilitiesValuesReturnT, UpdateTasksParamsArgs } from 'pages/tasks/types/function-types';
import { CreateUpdateTaskResponse } from 'services/production-task.model';
import { AssignResponsibleToTasksBody, SelectedTask, TaskTableModel } from 'services/task-table.model';

export const getAllTasksOnTheScreen = (tasks: TaskTableModel[]): SelectedTask[] => {
  return tasks.map((task) => ({
    id: task.id,
    departmentIds: task.department_ids,
    slots: task.assignee.map((assignee) => ({
      slot_id: assignee.slot_id,
      task_responsibility_id: assignee.task_responsibility_id,
      isUnassigned: !assignee.taskAssignment?.user,
    })),
  }));
};

export const prepareRequestBodyForBulkAssignUsers = (
  performers: Record<string, string | null>,
  tasks: SelectedTask[],
): AssignResponsibleToTasksBody[] => {
  const userIds = Object.values(performers);

  return userIds.map((userId, index) => {
    const taskSlotIds = tasks.map((task) => task.slots[index]?.slot_id);

    return {
      user_id: userId,
      task_slot_ids: taskSlotIds,
    };
  });
};

export const updateTasksFromWsResponse = ({ id, slotId, user, tasks }: UpdateTasksParamsArgs) => {
  const currentAssignAt = new Date().toISOString();
  return {
    ...tasks,
    data: tasks.data.map((task) => {
      if (task.id === id) {
        return {
          ...task,
          assignee: task.assignee.map((assignee) => {
            if (assignee.slot_id === slotId) {
              return {
                ...assignee,
                taskAssignment: { user },
              };
            }
            return assignee;
          }),
          task_slots: task.task_slots.map((slot) => (slot.id === slotId ? { ...slot, task_assignment_id: user.id } : slot)),
          is_assignment_in_progress: false,
          assignee_position: task.assignee_position.concat(user.position),
          assignee_department: task.assignee_department.concat(user.department),
          assigned_at: task.assigned_at.concat(currentAssignAt),
        };
      }
      return task;
    }),
  };
};

export const extractUpdatedTaskResponsibilitiesValues = (data: CreateUpdateTaskResponse) => {
  return data.responsibilities.reduce<UpdatedTaskResponsibilitiesValuesReturnT>(
    (acc, responsibility) => {
      responsibility.taskSlots.forEach((taskSlot) => {
        const { taskAssignment } = taskSlot;

        if (taskAssignment) {
          const { user } = taskAssignment;
          const positionSlot = user?.user_position_slots?.[0]?.position_slot;

          acc.positions.push(positionSlot?.position_type?.name || null);
          acc.departments.push(positionSlot?.department?.name || null);
          acc.assignAt.push(taskAssignment.assign_at || null);
        }
      });

      return acc;
    },
    {
      positions: [],
      departments: [],
      assignAt: [],
    },
  );
};
